.ProductenPage {    
    .title {
        &.container {
            margin-top: 120px;
            margin-bottom: 180px;
            padding: 0 var(--spacing-nav);
    
            @include mq($until: $viewport--sm) {
                margin-top: 40px;
                margin-bottom: 60px;
            }
        }
    }

    .product-detail {
        .products-list {
            margin-top: 80px;
        }        

        .intro-outro-textblock {
            min-height: 850px;
            display: grid;
            grid-template-columns: repeat(2, 50%);
            margin: 0 var(--spacing-nav);

            .text {
                margin-top: 50px;

                &-container {
                    max-width: 580px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                    flex-direction: column;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 0 40px;

                    .downloads {
                        margin-top: 100px;
                        margin-bottom: 0px;

                        @include mq($until: $viewport--sm) {
                            margin-top: 40px;
                        }
                    }
                }
            }

            .cta {
                margin-top: 50px;
            }
            
            &.intro {
                .image {
                    padding-left: 10px;
                }
            }
    
            &.outro {
                .image {
                    padding-right: 10px;
                }
            }

            @include mq($until: 1600px) {
                min-height: 650px;
            }

            @include mq($until: 900px) {
                min-height: 500px;
            }
           
            @include mq($until: $viewport--md) {
                display: flex;
                flex-direction: column;

                .text {
                    margin-top: 10px;

                    &-container {
                        padding: 0;
                        margin-right: 0;
                        margin-left: 0;
                    }
                }

                .cta {
                    margin-top: 10px;
                }

                &.intro {
                    .image {
                        margin-top: 40px;
                        padding-left: 0;
                    }
                }
                
                &.outro {
                    .image {
                        margin-bottom: 20px;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}