.downloads {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    width: 100%;
    max-width: 600px;

    > h3 {
        margin-bottom: 30px;
    }

    .download {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $cc-grey;
        padding: 10px 20px;
        border: 1px solid var(--primary-color);
        border-left: none;
        border-right: none;

        > * {
            color: var(--primary-color);
            transition: color .4s ease;
        }

        &:not(:first-of-type) {
            border-top: none;
        }

        &:hover {
            background-color: var(--primary-color);

            > * {
                color: white;
            }
        }
    }

    &-container {
        margin-top: 100px;

        @include mq($until: $viewport--sm) {
            margin-top: 60px;
        }
    }
}