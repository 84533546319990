.gallery {
    &-section {
        margin: 20px var(--spacing-nav);        

        .masonry {
            &-item {
                width: 50%;
                padding: 10px;

                @include mq($until: $viewport--md) {
                    width: 100%;
                    padding: 5px 0;
                }

                @include mq($from: $viewport--md) {                    
                    &:nth-of-type(2) {
                        margin-top: -330px;
                    }
                }
            }
        }

        .images {
            max-width: 2200px;
            margin-left: auto;
            margin-right: auto;   
            margin-top: 20px;          

            &-item {
               

                &.hide {
                    display: none;
                }

                .info {                                              
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;            
                    overflow: hidden;
                    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
                    transition: clip-path .4s ease;
                    z-index: 1;
                    display: flex;
                    align-items: flex-end;
                    padding: 50px;

                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        background-color: var(--primary-color);
                        position: absolute;
                        left: 0;
                        top: 0;
                        transition: opacity .4s ease;
                    }
        
                    &-container {
                        position: relative;
                        height: inherit;
                    }
        
                    h2 {
                        color: $cc-white;
                        user-select: none;
                        pointer-events: none;
                        position: relative;
                        z-index: 1;
                    }
        
                    .arrow {
                        user-select: none;
                        pointer-events: none;
                        position: absolute;
                        right: 50px;
                        top: 50px;
                        width: 50px;
                        height: 50px;
                        opacity: 0;
                        transition: opacity .4s ease;
        
                        svg {
                            width: 100%;
                            height: 100%;
                        }
                    }
        
                    & ~ .image {
                        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
                        transition: clip-path .4s ease;
                    }
        
                    &:hover {                        
                        clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0);
        
                        & ~ .image {
                            clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0);
                        }

                        &:before, .arrow {
                            opacity: 1;
                        }
                    }

                    @include mq($until: 450px) {
                        pointer-events: none;
                    }
                }
            }

            @include mq($until: 450px) {
                margin-top: 10px;
            }            
        }

        @include mq($until: 450px) {            
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}