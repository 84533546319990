a {
    text-decoration: none;
}

h1, h2, h3{
    font-family: $font-bold;
    color: $cc-black;
}

h1 {
    font-size: 60px;
    line-height: 75px;

    * {
        font-size: 60px !important;
        line-height: 75px !important;
    }

    a {
        color: $cc-black;
        padding-bottom: 5px;
        border-bottom: 3px solid $cc-black;
        transition: border-color .4s ease;

        &:hover, &:focus, &:active {
            color: $cc-black;
            border-color: rgba($cc-black, 0)
        }
    }

    @include mq($until: 1260px){
        font-size: 50px;
        line-height: 65px;
    
        * {
            font-size: 50px !important;
            line-height: 65px !important;
        }    
    }
    
    @include mq($until: $viewport--md){
        font-size: 40px;
        line-height: 55px;
    
        * {
            font-size: 40px !important;
            line-height: 55px !important;
        }    
    }
    
    @include mq($until: $viewport--sm){
        font-size: 25px;
        line-height: 40px;
    
        * {
            font-size: 25px !important;
            line-height: 40px !important;
        }    
    }
}

h2 {
    font-size: 50px;
    line-height: 60px;

    @include mq($until: 1260px){
        font-size: 40px;
        line-height: 50px;
    
        * {
            font-size: 40px !important;
            line-height: 50px !important;
        }    
    }
    
    @include mq($until: $viewport--md){
        font-size: 30px;
        line-height: 40px;
    
        * {
            font-size: 30px !important;
            line-height: 40px !important;
        }    
    }
    
    @include mq($until: $viewport--sm){
        font-size: 22px;
        line-height: 32px;
    
        * {
            font-size: 22px !important;
            line-height: 32px !important;
        }    
    }
}

h3 {
    font-size: 35px;
    line-height: 50px;

    @include mq($until: 1260px){
        font-size: 32px;
        line-height: 47px;
    
        * {
            font-size: 32px !important;
            line-height: 47px !important;
        }    
    }
    
    @include mq($until: $viewport--md){
        font-size: 28px;
        line-height: 42px;
    
        * {
            font-size: 28px !important;
            line-height: 42px !important;
        }    
    }
    
    @include mq($until: $viewport--sm){
        font-size: 20px;
        line-height: 34px;
    
        * {
            font-size: 20px !important;
            line-height: 34px !important;
        }    
    }
}

p, a, ul li, .Form label {
    font-size: 16px;
    line-height:25px;

    @include mq($until: 1000px) {
        font-size: 20px;
        line-height: 28px;
    }

    @include mq($until: $viewport--md) {
        font-size: 18px;
        line-height: 26px;
    }
}

p {
    a {
        color: var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
        padding-bottom: 2px;
        transition: border-color .4s ease;

        &:hover {
            color: var(--primary-color);
            border-color: rgba(255, 255, 255, 0);
        }
    }
}

ul {
    li {
        list-style: disc;
    }
}