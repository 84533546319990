.textblocks {
    &.blocks-variable-height {
        margin-top: 80px;
        
        .textblock {
            min-height: unset !important;
        }

        @include mq($until: $viewport--md) {
            margin-top: 0;         
        }
    }

    .textblock {
        @include mq($from: $desktop-size) {
            &.textblockNormal {
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                max-width: 1800px;
            }
        }
    }

    @include mq($until: $viewport--md) {
        .textblock {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin-top: 80px;
        }
    }

    .video-container {
        height: 1080px;
        
        video {
            height: inherit;
        }

        @include mq($until: 1500px) {
            height: 800px;
        }
       
        @include mq($until: 1200px) {
            height: 600px;
        }
        
        @include mq($until: 800px) {
            height: 400px;
        }
       
        @include mq($until: $viewport--sm) {
            height: 300px;
        }

        @include mq($from: 1200px) {
            margin-top: 200px;
        }
    }

    .image-container {
        height: 800px;
        overflow: hidden;
        position: relative;
        margin: 0;

        .image {
            width: 100%;
            height: 120%;
            position: absolute;
            z-index: -1;
            top: -40%;
        }

        @include mq($until: 1200px) {
            height: 600px;
            margin: 80px 0;
        }
        
        @include mq($until: $viewport--md) {
            height: 400px;
        }
        
        @include mq($until: $viewport--sm) {
            height: 250px;            
            margin-bottom: 60px;

            .image {
                top: -80%;
            }
        }

        @include mq($from: 1200px) {
            margin-top: 200px;
        }
    }
}

.titletextblocks {
    display: flex;
    flex-direction: column;

    .titletextblock {
        h2 {
            margin-bottom: 20px;

            @include mq($until: $viewport--md) {
                margin-bottom: 10px;
            }
        }
    }

    .titletextblock + .titletextblock {
        margin-top: 40px;

        @include mq($until: $viewport--md) {
            margin-top: 20px;
        }
    }
}

.textblock {
    display: flex;
    align-items: center;
    min-height: 1080px;

    &.electric-style {
        .text-container {
            h2 {
                color: $cc-electric;
            }

            .ctas {
                .cta {
                    &:hover {
                        background-color: $cc-electric;
                        color: $cc-white;
                    }
                }
            }
        }
    }

    &.single {
        &.textblockNormal {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            min-height: unset;
            padding-top: 200px;

            @include mq($until: $viewport--md) {
                padding-top: 100px;
            }
            
            @include mq($until: $viewport--sm) {
                padding-top: 80px;
            }
        }
    }
    
    &.image-horizontal {
        .image {
            width: 840px;
            height: 680px;
        }

        @include mq($until: 1400px) {
            .image {
                width: 600px;
                height: 470px;
            }
        }
    }
    
    &.image-right {
        flex-direction: row-reverse;
        justify-content: space-between;

        .image {
            margin-right: 0 !important;
        }        

        .text-container {
            min-width: 720px;
        }

        @include mq($until: 1560px) {
            .text-container {
                min-width: 420px;
                padding-right: 40px;
            }
        }
        
        @include mq($until: $viewport--sm) {
            .text-container {
                min-width: unset;
                padding-right: 0;                
            }
        }
    }
    
    &.textblockFullscreen {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;

        &.image-left {
            grid-template-areas: 'image left';

            > * {
                &:nth-child(1) {
                    grid-area: left;
                }
                
                &:nth-child(2) {
                    grid-area: image;
                }
            }
        }
        
        > * {
            width: 100%;
            height: 100%;
            
            &.left {
                background-color: var(--primary-color);                          
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 60px var(--spacing-nav);

                h2, .text {
                    color: $cc-white;
                }

                &.electric {
                    background-color: $cc-lightGrey;

                    h2 {
                        color: $cc-electric;
                    }

                    .text {
                        color: $cc-black;
                    }

                    .cta {
                        background-color: $cc-darkGrey;
                        color: $cc-black;

                        &:hover {
                            background-color: $cc-electric;
                            color: $cc-white;                            
                        }
                    }
                }
            }
        }

        .video-container {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 120px;
        }

        @include mq($until: 1000px) {
            display: flex;
            flex-direction: column;
            min-height: unset;

            .text {
                max-width: unset;

                &-container {
                    align-items: flex-start;
                }
            }
        }
    }

    &.fiftyFiftyBlock {
        align-items: flex-start;

        .text {
            margin-top: 0 !important;
        }

        > * {
            &:nth-of-type(1) {
                min-width: 720px;
            } 
        }

        @include mq($until: 1500px) {
            > * {
                &:nth-of-type(1) {
                    min-width: 600px;
                }
            }
        }
        
        @include mq($until: 1330px) {
            > * {
                &:nth-of-type(1) {
                    min-width: 420px;
                    padding-right: 60px;
                }
            }
        }
        
        @include mq($until: 900px) {
            flex-direction: column;
            
            > * {
                &:nth-of-type(1) {
                    min-width: unset;
                    padding-right: 0;
                }
            }

            .text-container {
                margin-top: 40px;
            }
        }
    }

    &.textblockNormal, &.fiftyFiftyBlock {
        padding: 80px var(--spacing-nav);

        &:not(.electric-style) {
            h2 {
                color: var(--primary-color);
            }
        }
    }

    &.imagesBlockWithText {
        
    }
    
    &.textblockNormal {                    
        &:not(.image-horizontal) {
            .image {
                min-width: 560px;
                max-width: 560px;
                min-height: 700px;
                max-height: 700px;
            }
        }

        .image {
            margin-right: 200px;

            img {
                max-height: inherit;
                min-height: inherit;
                max-width: inherit;
                min-width: inherit;
            }

        }
        
        @include mq($until: 1400px) {
            .image {
                margin-right: 120px;
            }
        }

        @include mq($until: 1280px) {
            &:not(.image-horizontal) {
                .image {
                    min-width: 400px;
                    max-width: 400px;
                    min-height: 540px;
                    max-height: 540px;
                }
            }
            
            .image {
                margin-right: 60px;
            }
        }
    } 
    
    &.imagesBlockWithText {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto repeat(2, 420px);
        grid-template-areas: 'image1 text'
        'image1 image2'
        'image3 image2';
        grid-gap: 20px;

        padding: 80px 0;

       

        .image {
            height: 100%;

            &:nth-of-type(1) {
                grid-area: image1;
            }
            
            &:nth-of-type(2) {
                grid-area: image2;
            }
            
            &:nth-of-type(3) {
                grid-area: image3;
            }
        }

        .text {
            grid-area: text;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            max-width: unset;
            margin-top: 0;
            margin-bottom: 160px;
            padding-left: 70px;
        }

        @include mq($until: 1600px) {
            padding-left: var(--spacing-nav);
            padding-right: var(--spacing-nav);
        }

        @include mq($until: 1450px) {
            grid-template-rows: auto repeat(2, 300px);
        }
        
        @include mq($until: 1000px) {
            grid-template-rows: auto repeat(2, 200px);

            .text {
                margin-bottom: 50px;
            }
        }
        
        @include mq($until: 900px) {
            grid-template-areas: 'text text'
            'image1 image2'
            'image3 image2';

            .text {
                padding-left: 0;
                justify-content: flex-start;
            }
        }

        @include mq($until: $viewport--sm) {
            grid-template-columns: 1fr;
            grid-template-rows: auto repeat(3, 200px);
            grid-template-areas: 'text'
            'image1'
            'image2'
            'image3';
            margin-top: 40px;

            .text {
                margin-bottom: 20px;
            }
        }
    }

    h2 {
        max-width: 560px;
    }

    .text {
        margin-top: 50px;
        max-width: 420px;

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .ctas {
        margin-top: 30px;
    }  

    @include mq($until: 1280px) {
        min-height: 800px;
    }
   
    @include mq($until: 1000px) {
        &.textblockNormal {
            &:not(.image-horizontal) {
                .image {
                    min-width: 260px;
                    max-width: 260px;
                    min-height: 350px;
                    max-height: 350px;
                }
            }
        }

        &.image-horizontal {
            .image {
                width: 420px;
                height: 320px;
            }
        }

        .text {
            margin-top: 20px;
        }
    
        .ctas {
            margin-top: 20px;
        } 
    }

    @include mq($until: $viewport--md) {
        min-height: auto;

        &.textblockNormal {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .image {
                max-width: unset !important;
                min-width: unset !important;
                margin-right: 0;
                width: 100%;
            }

            .text {
                &-container {
                    margin-top: 40px;
                }
            }
        }
    }

    @include mq($from: 1200px) {
        &.textblockNormal, &.fiftyFiftyBlock {
            &:not(.single) {
                padding: 0 var(--spacing-nav) 0;
                min-height: unset;
                margin-top: 200px;
            }
        }  
        
        &.textblockFullscreen {
            margin-top: 200px;
        }
    }
}