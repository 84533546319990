.cta {
    padding: 35px 70px 35px 55px;
    width: max-content;
    clip-path: polygon(100% 0, 100% calc(100% - 35px), calc(100% - 35px) 100%, 0 100%, 0 0);
    background-color: $cc-grey;
    color: $cc-darkerGrey;
    border-color: $cc-darkerGrey;
    transition: opacity .4s ease, background-color .4s ease, color .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    word-break: break-word;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 16px;
    font-weight: 700;

    &:hover {
        opacity: .7;
    }

    &:hover, &:active, &:focus {
        color: $cc-darkerGrey;
    }

    &:not(.orange) {
        &:hover {
            background-color: var(--primary-color);
            color: $cc-white;
            opacity: 1;
        }
    }

    &.orange {
        background-color: var(--primary-color);
        color: $cc-white;

        &:hover {
            background-color: $cc-lightGrey;
            color: $cc-black;
            opacity: 1;
        }
    }

    &.dark {
        background-color: $cc-darkestGrey;
        color: $cc-white;  
        
        &:hover {
            background-color: $cc-lightGrey;
            color: $cc-black;
            opacity: 1;
        }
    }

    @include mq($until: 1000px) {
        padding: 25px 50px 25px 35px;
    }
}

.ctas {
    display: flex; 
    flex-wrap: wrap;
    margin-bottom: -30px;

    .cta {
        margin-right: 30px;
        margin-bottom: 30px;
    }

    @include mq($until: $viewport--md) {
        .cta {
            margin-bottom: 20px;
        }
    }
}