.sliding-ctas {
    display: flex;
    flex-direction: column;
    height: 1200px;
    
    
    @include mq($until: 1500px) {
        height: 900px;
    }

    .row {
        display: flex;
        height: 100%;
        transition: height .4s ease;

        &.h-full {
            height: 53%;
        }

        &.h-min {
            height: 47%;
        }

        .slide {
            clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
            height: 100%;
            width: 50%;
            transition: clip-path .4s ease, width .4s ease;
            background-color: $cc-darkestGrey;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            padding: 30px;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
    
            &.w-full {
                width:51%;
            }
            
            &.w-min {
                width: 49%;
            }
            
    
            @include mq($from: 1600px) {
                padding: 60px;
            }
    
            &:before {
                content: '';
                position: absolute;
                background-color: var(--primary-color);
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity .4s ease;
                left: 0;
                bottom: 0;
            }
    
            h2 {
                position: relative;
                z-index: 2;
                color: $cc-white;            
                hyphens: auto;
            }
    
            .arrow {
                position: absolute;
                right: 30px;
                top: 30px;
                opacity: 0;
                transition: opacity .4s ease;
            }
            
            &:hover {
                clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0);            
    
                h2 {
                    margin-right: 160px;
                }
    
                .arrow {
                    opacity: 1;
                }
    
                &:before {
                    opacity: 1;
                }
            }
    
            @include mq($until: 1900px) {
                h2 {               
                    margin-right: 0;
                }
            }
            
            @include mq($until: 1600px) {
                h2 {               
                    font-size: 30px;
                    line-height: 40px;
                    margin-right: 120px;
                }
    
                &:hover {
                    clip-path: polygon(100% 0, 100% calc(100% - 120px), calc(100% - 120px) 100%, 0 100%, 0 0);
                }
            }
           
            @include mq($until: 1200px) {
                h2 {               
                    font-size: 26px;
                    line-height: 36px;
                    margin-right: 60px;
                }
    
                &:hover {
                    clip-path: polygon(100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 0);
                }
            }
        }
    
        .slide + .slide {
            margin-left: 10px;
        }
    
        @include mq($until: 1600px) {
            height: 960px;
        }
        
        @include mq($until: 1200px) {
            height: 600px;
        }
       
        @include mq($until: 960px) {
            flex-direction: column;
            height: auto;
            
            &.h-full {
                height: 100%;
            }

            &.h-min {
                height: 100%;
        }
    
            .slide {
                transition: clip-path .4s ease, height .4s ease;
                width: 100%;
                height: 300px;
                
                &.w-full {
                    width: 100%;
                }
                
                &.w-min {
                    width: 100%;
                }
                
                &:hover {      
                    width: 100%;      
                    height: 350px;
                }
            }
    
            .slide + .slide {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    
        @include mq($until: $viewport--sm) {
            .slide {
                height: 200px;
    
                .arrow {
                    transform: scale(.7);
                    right: 20px;
                    top: 20px;
                }

                h2 {
                    margin-right: 10px;
                }
                
                &:hover {      
                    height: 250px;
                }
            }
        }
    }

    @include mq($until: 960px) {        
        height: auto;
    }

    .row + .row {
        margin-top: 10px;
    }
}