@charset "UTF-8";
/* Style */
/* Settings: Base */
:root {
  --spacing-nav: 60px; }

@media only screen and (max-width: 768px) {
  :root {
    --spacing-nav: 40px; } }

@media only screen and (max-width: 600px) {
  :root {
    --spacing-nav: 30px; } }

/* Settings: Colors */
/* Colors: basic */
/* Colors: Custom */
:root {
  --primary-color: #FF3C1E; }

:root.electric {
  --primary-color: #209BD8;
  background-color: #E6E6E6; }
  :root.electric .logo-normal svg path {
    fill: #209BD8; }
  :root.electric .CookieConsent.customization {
    pointer-events: none; }
    :root.electric .CookieConsent.customization .normal-buttons .cta {
      background-color: #205069;
      color: #797878; }

:root:not(.electric) {
  background-color: #FF3C1E; }
  :root:not(.electric) body {
    background-color: white; }

/* Colors: Theme */
/* Settings: Fonts */
@font-face {
  font-family: 'Shapiro 45';
  src: url("../../fonts/shapiro/Shapiro-45WelterExtd.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-45WelterExtd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 45';
  src: url("../../fonts/shapiro/Shapiro-45WelterWide.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-45WelterWide.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 45';
  src: url("../../fonts/shapiro/Shapiro-45Welter.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-45Welter.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 45';
  src: url("../../fonts/shapiro/Shapiro-45WelterText.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-45WelterText.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 55';
  src: url("../../fonts/shapiro/Shapiro-55Middle.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-55Middle.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 55';
  src: url("../../fonts/shapiro/Shapiro-55MiddleText.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-55MiddleText.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 55';
  src: url("../../fonts/shapiro/Shapiro-55MiddleWide.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-55MiddleWide.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Shapiro 55';
  src: url("../../fonts/shapiro/Shapiro-55MiddleExtd.woff2") format("woff2"), url("../../fonts/shapiro/Shapiro-55MiddleExtd.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
:root {
  --structure-header-height: 200px; }

@media only screen and (max-width: 600px) {
  :root {
    --structure-header-height: 100px; } }

/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Functions: Units */
/* Functions: SVG */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: String */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Hover */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

body,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0); }

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0); }

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0); }

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0); }

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0); }

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0); }

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform; }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }

[data-aos='zoom-in'] {
  transform: scale(0.6); }

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos='zoom-out'] {
  transform: scale(1.2); }

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform; }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translate3d(0, 0, 0); }

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0); }

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0); }

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0); }

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0); }

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg); }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg); }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0); }

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg); }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg); }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0); }

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, [data-aos^=fade][data-aos^=fade], [data-aos^=zoom][data-aos^=zoom] {
    opacity: 1;
    transform: none !important; } }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; } }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.slick-dotted.slick-slider {
  @apply mb-0; }

.slick-slider {
  @apply mb-0; }
  .slick-slider .slick-slide:focus {
    @apply outline-none; }

.slick-track,
.slick-list {
  @apply h-full; }

.slick-dots {
  @apply static text-left; }

/* HR */
hr {
  margin-top: 24px;
  margin-bottom: 23px;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #3f51b5;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #3949ab;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 24px;
  padding-left: 24px; }
  ol li,
  ul li {
    margin-bottom: 5px;
    list-style-type: '\2014'; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 4px;
    margin-bottom: 4px; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: white;
  background-color: var(--primary-color); }

/* Heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700; }
  .fonts-loaded h1, .fonts-loaded
  h2, .fonts-loaded
  h3, .fonts-loaded
  h4, .fonts-loaded
  h5, .fonts-loaded
  h6 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: normal; }

h1 {
  font-family: "Shapiro 45", sans-serif, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 48px;
  color: #000000; }

h2 {
  font-family: "Shapiro 45", sans-serif, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  color: #000000; }

h3 {
  margin-bottom: 24px;
  font-size: 20px; }

h4 {
  margin-bottom: 24px;
  font-size: 18px; }

h5 {
  margin-bottom: 24px;
  font-size: 16px; }

h6 {
  margin-bottom: 24px;
  font-size: 14px; }

.Giga {
  margin-bottom: 24px;
  font-size: 96px;
  line-height: 1.1; }

.Mega {
  margin-bottom: 24px;
  font-size: 72px;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.2; }

.Milli {
  margin-bottom: 24px;
  font-size: 12px; }

.Micro {
  margin-bottom: 24px;
  font-size: 10px; }

/* Paragraph */
p {
  margin-bottom: 24px; }

.Lead {
  font-size: 18px; }

/* Main */
html {
  background-color: #ffffff;
  color: #4D4D4D;
  font-family: "Shapiro 45", sans-serif;
  height: 100%;
  scroll-behavior: smooth; }
  html.fonts-loaded {
    font-family: "Shapiro 45", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  position: relative;
  overflow-x: hidden; }

.hide {
  display: none; }

.inactive {
  opacity: .4;
  pointer-events: none; }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 24px;
  padding: 0.75em 1em 0.75em 1em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 32px; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 24px; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 0 1 auto; }
  @media screen and (max-width: 64em) {
    .Nav-list {
      flex-direction: column; } }

.Nav-item {
  margin: 0;
  list-style: none; }
  .Nav-item.has-dropdown {
    position: relative; }
    @media screen and (min-width: 105.6875em) {
      .Nav-item.has-dropdown:hover .Nav-dropdown {
        opacity: 1;
        visibility: visible; }
        .Nav-item.has-dropdown:hover .Nav-dropdown::before {
          height: 30px; } }

.Nav-link {
  position: relative;
  user-select: none;
  display: block;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #4D4D4D;
  text-decoration: none;
  margin-left: 30px;
  padding-bottom: 5px; }
  .Nav-link:hover, .Nav-link:focus, .Nav-link:active {
    color: #4D4D4D; }
  .Nav-link.is-active {
    color: #4D4D4D; }

.Nav-dropdown {
  position: absolute;
  opacity: 0;
  list-style: none;
  visibility: hidden;
  transition: opacity ease .3s, visibility ease .3s;
  top: 30px;
  text-align: left;
  margin: 0 auto;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  padding: 20px;
  background-color: #ffffff;
  width: 160px; }
  .Nav-dropdown.showDropdown {
    opacity: 1;
    visibility: visible; }
    .Nav-dropdown.showDropdown::before {
      height: 30px; }
  .Nav-dropdown-container .Nav-item a {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    transition: border-color .2s ease;
    line-height: 1;
    padding-bottom: 5px; }
  .Nav-dropdown-container .Nav-item a + a {
    padding-top: 10px; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.5em 0.75em 0.5em 0.75em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 24px; }

.Form-label {
  display: inline-block;
  margin-bottom: 6px; }
  .Form-item:not(.is-required) .Form-label::after {
    content: ' - Optional';
    margin-left: 6px; }
    html[lang^="nl"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Optioneel'; }
    html[lang^="fr"] .Form-item:not(.is-required) .Form-label::after {
      content: ' - Facultatif'; }
  .Form-item.is-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 6px;
  color: #bdbdbd;
  font-size: 12px; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 12px; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #e0e0e0; }

.Form-item.is-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 6px;
    margin-bottom: 0;
    padding-right: 24px;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-left: -6px; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-left: 6px; }

.Grid--withSmallGutter {
  margin-left: -12px; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-left: 12px; }

.Grid--withGutter {
  margin-left: -24px; }
  .Grid--withGutter > .Grid-cell {
    padding-left: 24px; }

.Grid--withLargeGutter {
  margin-left: -48px; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-left: 48px; }

.Grid--withExtraLargeGutter {
  margin-left: -96px; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-left: 96px; }

.Grid--withExtraExtraLargeGutter {
  margin-left: -96px; }
  .Grid--withExtraExtraLargeGutter > .Grid-cell {
    padding-left: 96px; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  width: 100%; }
  .Container--xxl {
    max-width: 1960px;
    padding-right: 100px;
    padding-left: 100px; }
  .Container--xl {
    max-width: 1480px; }
  .Container--lg {
    max-width: 1320px; }
  .Container--md {
    max-width: 1100px; }
  .Container--sm {
    max-width: 1000px; }
  .Container--xs {
    max-width: 640px; }
  @media screen and (min-width: 111.25em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 360px;
        padding-left: 360px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }
  @media screen and (min-width: 20em) and (max-width: 37.5625em) {
    .Container {
      max-width: 1140px;
      padding-right: 40px;
      padding-left: 40px; }
      .Container--xxl {
        max-width: 2000px;
        padding-right: 20px;
        padding-left: 20px; }
      .Container--xl {
        max-width: 1520px; }
      .Container--lg {
        max-width: 1360px; }
      .Container--md {
        max-width: 1140px; }
      .Container--sm {
        max-width: 1040px; }
      .Container--xs {
        max-width: 680px; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header;
  height: var(--structure-header-height); }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .Main > .Container {
    padding-top: 24px;
    padding-bottom: 24px; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 48px; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 24px; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 1px solid #e0e0e0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    vertical-align: middle;
    padding: 4px 8px 4px 8px; }
  .Table thead {
    background-color: #f5f5f5;
    border-bottom: 2px solid #e0e0e0; }
  .Table tbody tr + tr {
    border-top: 1px solid #e0e0e0; }
  .Table tfoot {
    background-color: #f5f5f5;
    border-top: 2px solid #e0e0e0; }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tbody tr {
        border: 1px solid #e0e0e0;
        border-bottom-width: 0; }
      .Table.is-responsive tfoot {
        border: 1px solid #e0e0e0;
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: inline-block;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-table-label]::before {
          content: attr(data-table-label);
          display: block;
          margin: -12px -12px 12px;
          padding: 12px;
          background-color: #fafafa;
          border-top: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 47.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Toggle {
  height: 24px;
  width: 30px;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg);
  z-index: 999999999; }
  .Toggle-item {
    height: 2px;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: #000000;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg); }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 11px;
        background-color: #000000;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 11px; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 22px; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 11px;
        background-color: #000000;
        transform: rotate(-135deg); }

/* Cookie */
.CookieConsent {
  background-color: white;
  color: #1f1e1e;
  transition: transform .2s ease, visibility .2s ease, max-width .2s ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 0;
  z-index: 900;
  max-width: 380px;
  right: 20px;
  bottom: 20px;
  padding: 30px;
  left: 20px; }
  .CookieConsent a {
    text-decoration: underline; }
  .CookieConsent.customization {
    pointer-events: none; }
    .CookieConsent.customization .normal-buttons .cta {
      background-color: #7a2a1f;
      color: #797878; }
    .CookieConsent.customization:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(31, 30, 30, 0.6); }
  .CookieConsent-custom {
    background-color: white;
    width: 500px;
    padding: 20px 25px;
    z-index: 1000; }
    .CookieConsent-custom.hidden {
      display: none; }
    .CookieConsent-custom .title {
      font-weight: 600;
      margin-bottom: 10px; }
    .CookieConsent-custom .text {
      font-size: 12px; }
    .CookieConsent-custom .accept-compliance-cookie {
      margin-top: 10px; }
    .CookieConsent-custom .choices-container {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #1f1e1e33; }
    .CookieConsent-custom .choices .checkbox + .checkbox {
      margin-top: 20px; }
    .CookieConsent-custom .choices .checkbox.required .input {
      pointer-events: none;
      opacity: .6;
      filter: grayscale(1); }
    .CookieConsent-custom .choices .checkbox .input .fake-box {
      width: 15px;
      height: 15px;
      border: 2px solid #1f1e1e;
      transition: border-color .2s ease, background-color .2s ease; }
    .CookieConsent-custom .choices .checkbox .input label {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .CookieConsent-custom .choices .checkbox .input label > .fake-box {
        margin-right: 5px; }
      .CookieConsent-custom .choices .checkbox .input label span {
        font-size: 14px;
        line-height: 1; }
    .CookieConsent-custom .choices .checkbox .input input {
      display: none; }
      .CookieConsent-custom .choices .checkbox .input input:checked ~ label > .fake-box {
        border-color: #1f1e1e;
        background-color: #1f1e1e; }
    .CookieConsent-custom .choices .checkbox .info {
      display: none;
      font-size: 12px;
      margin-top: 5px; }
      .CookieConsent-custom .choices .checkbox .info-container {
        margin-top: 5px; }
      .CookieConsent-custom .choices .checkbox .info-trigger {
        font-size: 14px;
        border-bottom: 1px solid white;
        cursor: pointer;
        transition: opacity .2s ease; }
        .CookieConsent-custom .choices .checkbox .info-trigger:hover {
          opacity: .7; }
      .CookieConsent-custom .choices .checkbox .info-checkbox {
        display: none; }
        .CookieConsent-custom .choices .checkbox .info-checkbox:checked ~ .info {
          display: flex; }
        .CookieConsent-custom .choices .checkbox .info-checkbox:checked ~ .info-trigger {
          display: none; }
    .CookieConsent-custom .customize-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px; }
      .CookieConsent-custom .customize-buttons .customize-back-btn {
        margin-top: 10px;
        cursor: pointer;
        font-size: 14px;
        margin-bottom: 0; }
  .CookieConsent-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(31, 30, 30, 0.6);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s ease;
    pointer-events: none; }
    .CookieConsent-container.is-active {
      opacity: 1;
      pointer-events: all; }
  .CookieConsent .normal-buttons, .CookieConsent .customize-buttons {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%; }
    .CookieConsent .normal-buttons.hidden, .CookieConsent .customize-buttons.hidden {
      display: none; }
  .CookieConsent .customize-container {
    margin-top: 30px; }
    .CookieConsent .customize-container.hidden {
      display: none; }

.CookieConsent:not(.is-active) {
  transform: translateY(100%);
  visibility: hidden; }

.CookieConsent > .Container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.Close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 16px;
  font-weight: 400; }

.Accept, .customize-btn {
  cursor: pointer;
  line-height: 1;
  width: 100%;
  color: white;
  padding: 20px 25px 20px 20px !important;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  transition: background-color .2s ease, color .2s ease;
  clip-path: polygon(100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0) !important; }
  .Accept:hover, .customize-btn:hover {
    color: #1f1e1e;
    background-color: white; }

.customize-btn {
  margin-top: 10px;
  margin-bottom: 0; }

.nav {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .nav-primary .link.active, .nav-secondary .link.active {
    color: var(--primary-color); }
  .nav-primary .link + .link, .nav-secondary .link + .link {
    margin-left: 40px; }
    @media screen and (max-width: 100em) {
      .nav-primary .link + .link, .nav-secondary .link + .link {
        margin-left: 20px; } }
  .nav-primary {
    margin-top: 5px; }
    .nav-primary .link {
      font-size: 18px; }
  .nav-secondary {
    margin-bottom: 5px;
    display: flex; }
    .nav-secondary * {
      color: #B3B3B3; }
      .nav-secondary *:hover, .nav-secondary *:focus, .nav-secondary *:active {
        color: #B3B3B3; }
    .nav-secondary .link {
      font-size: 12px;
      font-family: "Shapiro 45", sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1px; }
    .nav-secondary .link + .link {
      margin-left: 20px; }
    .nav-secondary .socials {
      margin-left: 20px;
      display: flex; }
      .nav-secondary .socials .link {
        display: flex; }
      .nav-secondary .socials .link + .link {
        margin-left: 10px; }
      .nav-secondary .socials svg path {
        fill: #B3B3B3; }
    .nav-secondary .language {
      margin-left: 25px;
      display: flex;
      align-items: center; }
      .nav-secondary .language > .line {
        margin-right: 20px;
        width: 2px;
        height: 20px;
        background-color: #B3B3B3; }
      .nav-secondary .language > a:hover {
        color: var(--primary-color); }
      .nav-secondary .language > a + a {
        margin-left: 10px !important; }
      @media screen and (max-width: 84.375em) {
        .nav-secondary .language {
          margin-left: 0;
          margin-top: 20px; }
          .nav-secondary .language > .line {
            display: none; }
          .nav-secondary .language > a + a {
            margin-top: 0 !important; } }
  @media screen and (min-width: 84.375em) {
    .nav-primary .link:hover, .nav-secondary .link:hover {
      color: var(--primary-color) !important; } }
  @media screen and (max-width: 84.375em) {
    .nav-primary .link:hover, .nav-secondary .link:hover {
      color: #4D4D4D !important; } }
  .nav-hamburger--icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 44px;
    height: 44px;
    cursor: pointer; }
    .nav-hamburger--icon span {
      margin: 2px 0;
      width: 30px;
      height: 3px;
      background-color: #4D4D4D;
      transition: transform .4s ease, background-color .4s ease; }
      .nav-hamburger--icon span:last-of-type {
        clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
        transition: transform .4s ease, clip-path .4s ease, background-color .4s ease; }
    @media screen and (min-width: 84.375em) {
      .nav-hamburger--icon {
        display: none; } }
  .nav-hamburger--checkbox {
    display: none; }
    @media screen and (max-width: 84.375em) {
      .nav-hamburger--checkbox ~ .logo-container, .nav-hamburger--checkbox ~ .nav-hamburger--icon {
        z-index: 1; }
      .nav-hamburger--checkbox ~ .nav {
        position: fixed;
        transition: transform .4s ease;
        transform: translateY(-100vh);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--primary-color);
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse; }
        .nav-hamburger--checkbox ~ .nav .nav-secondary {
          margin-top: 20px; }
          .nav-hamburger--checkbox ~ .nav .nav-secondary * {
            color: #F2F2F2; }
        .nav-hamburger--checkbox ~ .nav .nav-primary * {
          color: white; }
        .nav-hamburger--checkbox ~ .nav .nav-secondary, .nav-hamburger--checkbox ~ .nav .nav-primary {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .nav-hamburger--checkbox ~ .nav .nav-secondary .link + .link, .nav-hamburger--checkbox ~ .nav .nav-primary .link + .link {
            margin-left: 0;
            margin-top: 5px; }
          .nav-hamburger--checkbox ~ .nav .nav-secondary .link.active, .nav-hamburger--checkbox ~ .nav .nav-primary .link.active {
            color: #4D4D4D; }
      .nav-hamburger--checkbox:checked ~ .logo-container, .nav-hamburger--checkbox:checked ~ .nav-hamburger--icon {
        position: fixed; }
      .nav-hamburger--checkbox:checked ~ .logo-container svg path {
        fill: white; }
      .nav-hamburger--checkbox:checked ~ .nav {
        transform: translateY(0); }
        .nav-hamburger--checkbox:checked ~ .nav-hamburger--icon {
          top: 78px;
          right: 60px; }
          .nav-hamburger--checkbox:checked ~ .nav-hamburger--icon span {
            background-color: white; }
            .nav-hamburger--checkbox:checked ~ .nav-hamburger--icon span:first-of-type {
              transform: translateY(3px) rotate(45deg); }
            .nav-hamburger--checkbox:checked ~ .nav-hamburger--icon span:last-of-type {
              transform: translateY(-4px) rotate(-45deg);
              clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%); } }
    @media screen and (max-width: 37.5em) {
      .nav-hamburger--checkbox ~ .nav-hamburger--icon {
        position: absolute;
        top: 28px;
        right: 20px; }
      .nav-hamburger--checkbox:checked ~ .nav-hamburger--icon {
        top: 28px;
        right: 20px; } }

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-nav); }
  .Header .nav-primary * {
    color: #4D4D4D; }
  .Header.light .nav-primary * {
    color: white; }
  .Header.light .nav-hamburger--icon span {
    background-color: white; }
  .Header a {
    font-family: "Shapiro 55", sans-serif; }
  .Header .logo-normal {
    max-width: 450px; }
    .Header .logo-normal svg {
      max-width: inherit; }
      .Header .logo-normal svg path {
        transition: fill .4s ease; }
    @media screen and (max-width: 93.75em) {
      .Header .logo-normal {
        max-width: 400px; } }
    @media screen and (max-width: 37.5em) {
      .Header .logo-normal {
        max-width: 170px; } }
  .Header .title-to-logo {
    width: 540px;
    height: 80px;
    overflow: hidden;
    position: relative; }
    .Header .title-to-logo .content {
      position: absolute;
      top: 0;
      left: 0;
      transition: transform .4s ease; }
      .Header .title-to-logo .content .title, .Header .title-to-logo .content .logo {
        width: 540px;
        height: 80px; }
      .Header .title-to-logo .content .title h1 {
        color: white; }
      .Header .title-to-logo .content .logo svg {
        width: 100%; }
    .Header .title-to-logo.active .content {
      transform: translateY(-80px); }
    @media screen and (max-width: 93.75em) {
      .Header .title-to-logo {
        width: 400px; }
        .Header .title-to-logo .content .title, .Header .title-to-logo .content .logo {
          width: 400px; }
        .Header .title-to-logo .content .title h1 {
          font-size: 45px; } }
    @media screen and (max-width: 37.5em) {
      .Header .title-to-logo {
        width: 170px; }
        .Header .title-to-logo .content .title, .Header .title-to-logo .content .logo {
          width: 170px; }
        .Header .title-to-logo .content .logo svg {
          height: 100%; }
        .Header .title-to-logo .content .title {
          display: flex;
          align-items: center;
          transform: translateY(-2px); }
          .Header .title-to-logo .content .title h1 {
            font-size: 20px; } }

.page-header:not(.with-image) .title {
  margin-top: 120px;
  margin-bottom: 180px; }
  @media screen and (max-width: 48em) {
    .page-header:not(.with-image) .title {
      margin-top: 60px;
      margin-bottom: 100px; } }
  @media screen and (max-width: 37.5em) {
    .page-header:not(.with-image) .title {
      margin-top: 40px;
      margin-bottom: 60px;
      word-break: break-word; } }

.page-header:not(.with-image) .image {
  width: 100%;
  height: 120%;
  position: absolute;
  z-index: -1;
  top: -20%; }
  .page-header:not(.with-image) .image-container {
    position: relative;
    overflow: hidden;
    height: 840px; }
    @media screen and (max-width: 68.75em) {
      .page-header:not(.with-image) .image-container {
        height: 600px; } }
    @media screen and (max-width: 48em) {
      .page-header:not(.with-image) .image-container {
        height: 400px; } }
    @media screen and (max-width: 37.5em) {
      .page-header:not(.with-image) .image-container {
        height: 300px; } }

.page-header.with-image {
  margin-top: calc(var(--structure-header-height) * -1);
  border-bottom: 5px solid var(--primary-color);
  position: relative; }
  .page-header.with-image .image {
    height: calc(100vh - 5px);
    background-attachment: fixed; }
    @media screen and (max-width: 37.5em) {
      .page-header.with-image .image {
        background-attachment: unset; } }
  .page-header.with-image .logo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--spacing-nav); }
    .page-header.with-image .logo svg {
      width: 100%;
      height: 100%; }
  .page-header.with-image:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); }
  @media screen and (max-width: 48em) {
    .page-header.with-image.with-image .image {
      height: 660px; } }
  @media screen and (max-width: 37.5em) {
    .page-header.with-image.with-image .image {
      height: 500px; } }

a {
  text-decoration: none; }

h1, h2, h3 {
  font-family: "Shapiro 55", sans-serif;
  color: #4D4D4D; }

h1 {
  font-size: 60px;
  line-height: 75px; }
  h1 * {
    font-size: 60px !important;
    line-height: 75px !important; }
  h1 a {
    color: #4D4D4D;
    padding-bottom: 5px;
    border-bottom: 3px solid #4D4D4D;
    transition: border-color .4s ease; }
    h1 a:hover, h1 a:focus, h1 a:active {
      color: #4D4D4D;
      border-color: rgba(77, 77, 77, 0); }
  @media screen and (max-width: 78.75em) {
    h1 {
      font-size: 50px;
      line-height: 65px; }
      h1 * {
        font-size: 50px !important;
        line-height: 65px !important; } }
  @media screen and (max-width: 48em) {
    h1 {
      font-size: 40px;
      line-height: 55px; }
      h1 * {
        font-size: 40px !important;
        line-height: 55px !important; } }
  @media screen and (max-width: 37.5em) {
    h1 {
      font-size: 25px;
      line-height: 40px; }
      h1 * {
        font-size: 25px !important;
        line-height: 40px !important; } }

h2 {
  font-size: 50px;
  line-height: 60px; }
  @media screen and (max-width: 78.75em) {
    h2 {
      font-size: 40px;
      line-height: 50px; }
      h2 * {
        font-size: 40px !important;
        line-height: 50px !important; } }
  @media screen and (max-width: 48em) {
    h2 {
      font-size: 30px;
      line-height: 40px; }
      h2 * {
        font-size: 30px !important;
        line-height: 40px !important; } }
  @media screen and (max-width: 37.5em) {
    h2 {
      font-size: 22px;
      line-height: 32px; }
      h2 * {
        font-size: 22px !important;
        line-height: 32px !important; } }

h3 {
  font-size: 35px;
  line-height: 50px; }
  @media screen and (max-width: 78.75em) {
    h3 {
      font-size: 32px;
      line-height: 47px; }
      h3 * {
        font-size: 32px !important;
        line-height: 47px !important; } }
  @media screen and (max-width: 48em) {
    h3 {
      font-size: 28px;
      line-height: 42px; }
      h3 * {
        font-size: 28px !important;
        line-height: 42px !important; } }
  @media screen and (max-width: 37.5em) {
    h3 {
      font-size: 20px;
      line-height: 34px; }
      h3 * {
        font-size: 20px !important;
        line-height: 34px !important; } }

p, a, ul li, .Form label {
  font-size: 16px;
  line-height: 25px; }
  @media screen and (max-width: 62.5em) {
    p, a, ul li, .Form label {
      font-size: 20px;
      line-height: 28px; } }
  @media screen and (max-width: 48em) {
    p, a, ul li, .Form label {
      font-size: 18px;
      line-height: 26px; } }

p a {
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  padding-bottom: 2px;
  transition: border-color .4s ease; }
  p a:hover {
    color: var(--primary-color);
    border-color: rgba(255, 255, 255, 0); }

ul li {
  list-style: disc; }

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto; }
  .container--big {
    max-width: 1600px; }
  .container--medium {
    max-width: 1060px; }
  .container--small {
    max-width: 860px; }
  .container--extrasmall {
    max-width: 560px; }
  @media screen and (max-width: 100em) {
    .container:not(.without-padding) {
      padding-left: var(--spacing-nav);
      padding-right: var(--spacing-nav); } }

.cta-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 200px; }
  .cta-block h1 {
    color: #4D4D4D; }
  .cta-block .ctas {
    margin-top: 50px; }
  @media screen and (max-width: 48em) {
    .cta-block {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 37.5em) {
    .cta-block {
      min-height: unset; } }

.cta {
  padding: 35px 70px 35px 55px;
  width: max-content;
  clip-path: polygon(100% 0, 100% calc(100% - 35px), calc(100% - 35px) 100%, 0 100%, 0 0);
  background-color: #F2F2F2;
  color: #4D4D4D;
  border-color: #4D4D4D;
  transition: opacity .4s ease, background-color .4s ease, color .4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  word-break: break-word;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 700; }
  .cta:hover {
    opacity: .7; }
  .cta:hover, .cta:active, .cta:focus {
    color: #4D4D4D; }
  .cta:not(.orange):hover {
    background-color: var(--primary-color);
    color: white;
    opacity: 1; }
  .cta.orange {
    background-color: var(--primary-color);
    color: white; }
    .cta.orange:hover {
      background-color: #F4F4F4;
      color: #4D4D4D;
      opacity: 1; }
  .cta.dark {
    background-color: #3B3B3B;
    color: white; }
    .cta.dark:hover {
      background-color: #F4F4F4;
      color: #4D4D4D;
      opacity: 1; }
  @media screen and (max-width: 62.5em) {
    .cta {
      padding: 25px 50px 25px 35px; } }

.ctas {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px; }
  .ctas .cta {
    margin-right: 30px;
    margin-bottom: 30px; }
  @media screen and (max-width: 48em) {
    .ctas .cta {
      margin-bottom: 20px; } }

.sliding-ctas {
  display: flex;
  flex-direction: column;
  height: 1200px; }
  @media screen and (max-width: 93.75em) {
    .sliding-ctas {
      height: 900px; } }
  .sliding-ctas .row {
    display: flex;
    height: 100%;
    transition: height .4s ease; }
    .sliding-ctas .row.h-full {
      height: 53%; }
    .sliding-ctas .row.h-min {
      height: 47%; }
    .sliding-ctas .row .slide {
      clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
      height: 100%;
      width: 50%;
      transition: clip-path .4s ease, width .4s ease;
      background-color: #3B3B3B;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 30px;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center; }
      .sliding-ctas .row .slide.w-full {
        width: 51%; }
      .sliding-ctas .row .slide.w-min {
        width: 49%; }
      @media screen and (min-width: 100em) {
        .sliding-ctas .row .slide {
          padding: 60px; } }
      .sliding-ctas .row .slide:before {
        content: '';
        position: absolute;
        background-color: var(--primary-color);
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity .4s ease;
        left: 0;
        bottom: 0; }
      .sliding-ctas .row .slide h2 {
        position: relative;
        z-index: 2;
        color: white;
        hyphens: auto; }
      .sliding-ctas .row .slide .arrow {
        position: absolute;
        right: 30px;
        top: 30px;
        opacity: 0;
        transition: opacity .4s ease; }
      .sliding-ctas .row .slide:hover {
        clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0); }
        .sliding-ctas .row .slide:hover h2 {
          margin-right: 160px; }
        .sliding-ctas .row .slide:hover .arrow {
          opacity: 1; }
        .sliding-ctas .row .slide:hover:before {
          opacity: 1; }
      @media screen and (max-width: 118.75em) {
        .sliding-ctas .row .slide h2 {
          margin-right: 0; } }
      @media screen and (max-width: 100em) {
        .sliding-ctas .row .slide h2 {
          font-size: 30px;
          line-height: 40px;
          margin-right: 120px; }
        .sliding-ctas .row .slide:hover {
          clip-path: polygon(100% 0, 100% calc(100% - 120px), calc(100% - 120px) 100%, 0 100%, 0 0); } }
      @media screen and (max-width: 75em) {
        .sliding-ctas .row .slide h2 {
          font-size: 26px;
          line-height: 36px;
          margin-right: 60px; }
        .sliding-ctas .row .slide:hover {
          clip-path: polygon(100% 0, 100% calc(100% - 60px), calc(100% - 60px) 100%, 0 100%, 0 0); } }
    .sliding-ctas .row .slide + .slide {
      margin-left: 10px; }
    @media screen and (max-width: 100em) {
      .sliding-ctas .row {
        height: 960px; } }
    @media screen and (max-width: 75em) {
      .sliding-ctas .row {
        height: 600px; } }
    @media screen and (max-width: 60em) {
      .sliding-ctas .row {
        flex-direction: column;
        height: auto; }
        .sliding-ctas .row.h-full {
          height: 100%; }
        .sliding-ctas .row.h-min {
          height: 100%; }
        .sliding-ctas .row .slide {
          transition: clip-path .4s ease, height .4s ease;
          width: 100%;
          height: 300px; }
          .sliding-ctas .row .slide.w-full {
            width: 100%; }
          .sliding-ctas .row .slide.w-min {
            width: 100%; }
          .sliding-ctas .row .slide:hover {
            width: 100%;
            height: 350px; }
        .sliding-ctas .row .slide + .slide {
          margin-left: 0;
          margin-top: 10px; } }
    @media screen and (max-width: 37.5em) {
      .sliding-ctas .row .slide {
        height: 200px; }
        .sliding-ctas .row .slide .arrow {
          transform: scale(0.7);
          right: 20px;
          top: 20px; }
        .sliding-ctas .row .slide h2 {
          margin-right: 10px; }
        .sliding-ctas .row .slide:hover {
          height: 250px; } }
  @media screen and (max-width: 60em) {
    .sliding-ctas {
      height: auto; } }
  .sliding-ctas .row + .row {
    margin-top: 10px; }

.image.skewed-corner {
  clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0); }
  @media screen and (max-width: 81.25em) {
    .image.skewed-corner {
      clip-path: polygon(100% 0, 100% calc(100% - 140px), calc(100% - 140px) 100%, 0 100%, 0 0); } }
  @media screen and (max-width: 75em) {
    .image.skewed-corner {
      clip-path: polygon(100% 0, 100% calc(100% - 120px), calc(100% - 120px) 100%, 0 100%, 0 0); } }
  @media screen and (max-width: 68.75em) {
    .image.skewed-corner {
      clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0); } }
  @media screen and (max-width: 62.5em) {
    .image.skewed-corner {
      clip-path: polygon(100% 0, 100% calc(100% - 80px), calc(100% - 80px) 100%, 0 100%, 0 0); } }

@media screen and (max-width: 37.5em) {
  .image.rellax {
    position: relative !important;
    top: 0 !important;
    transform: translate3d(0, 0, 0) !important; } }

.textblocks.blocks-variable-height {
  margin-top: 80px; }
  .textblocks.blocks-variable-height .textblock {
    min-height: unset !important; }
  @media screen and (max-width: 48em) {
    .textblocks.blocks-variable-height {
      margin-top: 0; } }

@media screen and (min-width: 118.75em) {
  .textblocks .textblock.textblockNormal {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1800px; } }

@media screen and (max-width: 48em) {
  .textblocks .textblock {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 80px; } }

.textblocks .video-container {
  height: 1080px; }
  .textblocks .video-container video {
    height: inherit; }
  @media screen and (max-width: 93.75em) {
    .textblocks .video-container {
      height: 800px; } }
  @media screen and (max-width: 75em) {
    .textblocks .video-container {
      height: 600px; } }
  @media screen and (max-width: 50em) {
    .textblocks .video-container {
      height: 400px; } }
  @media screen and (max-width: 37.5em) {
    .textblocks .video-container {
      height: 300px; } }
  @media screen and (min-width: 75em) {
    .textblocks .video-container {
      margin-top: 200px; } }

.textblocks .image-container {
  height: 800px;
  overflow: hidden;
  position: relative;
  margin: 0; }
  .textblocks .image-container .image {
    width: 100%;
    height: 120%;
    position: absolute;
    z-index: -1;
    top: -40%; }
  @media screen and (max-width: 75em) {
    .textblocks .image-container {
      height: 600px;
      margin: 80px 0; } }
  @media screen and (max-width: 48em) {
    .textblocks .image-container {
      height: 400px; } }
  @media screen and (max-width: 37.5em) {
    .textblocks .image-container {
      height: 250px;
      margin-bottom: 60px; }
      .textblocks .image-container .image {
        top: -80%; } }
  @media screen and (min-width: 75em) {
    .textblocks .image-container {
      margin-top: 200px; } }

.titletextblocks {
  display: flex;
  flex-direction: column; }
  .titletextblocks .titletextblock h2 {
    margin-bottom: 20px; }
    @media screen and (max-width: 48em) {
      .titletextblocks .titletextblock h2 {
        margin-bottom: 10px; } }
  .titletextblocks .titletextblock + .titletextblock {
    margin-top: 40px; }
    @media screen and (max-width: 48em) {
      .titletextblocks .titletextblock + .titletextblock {
        margin-top: 20px; } }

.textblock {
  display: flex;
  align-items: center;
  min-height: 1080px; }
  .textblock.electric-style .text-container h2 {
    color: #209BD8; }
  .textblock.electric-style .text-container .ctas .cta:hover {
    background-color: #209BD8;
    color: white; }
  .textblock.single.textblockNormal {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    min-height: unset;
    padding-top: 200px; }
    @media screen and (max-width: 48em) {
      .textblock.single.textblockNormal {
        padding-top: 100px; } }
    @media screen and (max-width: 37.5em) {
      .textblock.single.textblockNormal {
        padding-top: 80px; } }
  .textblock.image-horizontal .image {
    width: 840px;
    height: 680px; }
  @media screen and (max-width: 87.5em) {
    .textblock.image-horizontal .image {
      width: 600px;
      height: 470px; } }
  .textblock.image-right {
    flex-direction: row-reverse;
    justify-content: space-between; }
    .textblock.image-right .image {
      margin-right: 0 !important; }
    .textblock.image-right .text-container {
      min-width: 720px; }
    @media screen and (max-width: 97.5em) {
      .textblock.image-right .text-container {
        min-width: 420px;
        padding-right: 40px; } }
    @media screen and (max-width: 37.5em) {
      .textblock.image-right .text-container {
        min-width: unset;
        padding-right: 0; } }
  .textblock.textblockFullscreen {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px; }
    .textblock.textblockFullscreen.image-left {
      grid-template-areas: 'image left'; }
      .textblock.textblockFullscreen.image-left > *:nth-child(1) {
        grid-area: left; }
      .textblock.textblockFullscreen.image-left > *:nth-child(2) {
        grid-area: image; }
    .textblock.textblockFullscreen > * {
      width: 100%;
      height: 100%; }
      .textblock.textblockFullscreen > *.left {
        background-color: var(--primary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px var(--spacing-nav); }
        .textblock.textblockFullscreen > *.left h2, .textblock.textblockFullscreen > *.left .text {
          color: white; }
        .textblock.textblockFullscreen > *.left.electric {
          background-color: #F4F4F4; }
          .textblock.textblockFullscreen > *.left.electric h2 {
            color: #209BD8; }
          .textblock.textblockFullscreen > *.left.electric .text {
            color: #4D4D4D; }
          .textblock.textblockFullscreen > *.left.electric .cta {
            background-color: #B3B3B3;
            color: #4D4D4D; }
            .textblock.textblockFullscreen > *.left.electric .cta:hover {
              background-color: #209BD8;
              color: white; }
    .textblock.textblockFullscreen .video-container {
      margin-bottom: 0; }
    .textblock.textblockFullscreen:last-child {
      margin-bottom: 120px; }
    @media screen and (max-width: 62.5em) {
      .textblock.textblockFullscreen {
        display: flex;
        flex-direction: column;
        min-height: unset; }
        .textblock.textblockFullscreen .text {
          max-width: unset; }
          .textblock.textblockFullscreen .text-container {
            align-items: flex-start; } }
  .textblock.fiftyFiftyBlock {
    align-items: flex-start; }
    .textblock.fiftyFiftyBlock .text {
      margin-top: 0 !important; }
    .textblock.fiftyFiftyBlock > *:nth-of-type(1) {
      min-width: 720px; }
    @media screen and (max-width: 93.75em) {
      .textblock.fiftyFiftyBlock > *:nth-of-type(1) {
        min-width: 600px; } }
    @media screen and (max-width: 83.125em) {
      .textblock.fiftyFiftyBlock > *:nth-of-type(1) {
        min-width: 420px;
        padding-right: 60px; } }
    @media screen and (max-width: 56.25em) {
      .textblock.fiftyFiftyBlock {
        flex-direction: column; }
        .textblock.fiftyFiftyBlock > *:nth-of-type(1) {
          min-width: unset;
          padding-right: 0; }
        .textblock.fiftyFiftyBlock .text-container {
          margin-top: 40px; } }
  .textblock.textblockNormal, .textblock.fiftyFiftyBlock {
    padding: 80px var(--spacing-nav); }
    .textblock.textblockNormal:not(.electric-style) h2, .textblock.fiftyFiftyBlock:not(.electric-style) h2 {
      color: var(--primary-color); }
  .textblock.textblockNormal:not(.image-horizontal) .image {
    min-width: 560px;
    max-width: 560px;
    min-height: 700px;
    max-height: 700px; }
  .textblock.textblockNormal .image {
    margin-right: 200px; }
    .textblock.textblockNormal .image img {
      max-height: inherit;
      min-height: inherit;
      max-width: inherit;
      min-width: inherit; }
  @media screen and (max-width: 87.5em) {
    .textblock.textblockNormal .image {
      margin-right: 120px; } }
  @media screen and (max-width: 80em) {
    .textblock.textblockNormal:not(.image-horizontal) .image {
      min-width: 400px;
      max-width: 400px;
      min-height: 540px;
      max-height: 540px; }
    .textblock.textblockNormal .image {
      margin-right: 60px; } }
  .textblock.imagesBlockWithText {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto repeat(2, 420px);
    grid-template-areas: 'image1 text' 'image1 image2' 'image3 image2';
    grid-gap: 20px;
    padding: 80px 0; }
    .textblock.imagesBlockWithText .image {
      height: 100%; }
      .textblock.imagesBlockWithText .image:nth-of-type(1) {
        grid-area: image1; }
      .textblock.imagesBlockWithText .image:nth-of-type(2) {
        grid-area: image2; }
      .textblock.imagesBlockWithText .image:nth-of-type(3) {
        grid-area: image3; }
    .textblock.imagesBlockWithText .text {
      grid-area: text;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      max-width: unset;
      margin-top: 0;
      margin-bottom: 160px;
      padding-left: 70px; }
    @media screen and (max-width: 100em) {
      .textblock.imagesBlockWithText {
        padding-left: var(--spacing-nav);
        padding-right: var(--spacing-nav); } }
    @media screen and (max-width: 90.625em) {
      .textblock.imagesBlockWithText {
        grid-template-rows: auto repeat(2, 300px); } }
    @media screen and (max-width: 62.5em) {
      .textblock.imagesBlockWithText {
        grid-template-rows: auto repeat(2, 200px); }
        .textblock.imagesBlockWithText .text {
          margin-bottom: 50px; } }
    @media screen and (max-width: 56.25em) {
      .textblock.imagesBlockWithText {
        grid-template-areas: 'text text' 'image1 image2' 'image3 image2'; }
        .textblock.imagesBlockWithText .text {
          padding-left: 0;
          justify-content: flex-start; } }
    @media screen and (max-width: 37.5em) {
      .textblock.imagesBlockWithText {
        grid-template-columns: 1fr;
        grid-template-rows: auto repeat(3, 200px);
        grid-template-areas: 'text' 'image1' 'image2' 'image3';
        margin-top: 40px; }
        .textblock.imagesBlockWithText .text {
          margin-bottom: 20px; } }
  .textblock h2 {
    max-width: 560px; }
  .textblock .text {
    margin-top: 50px;
    max-width: 420px; }
    .textblock .text-container {
      display: flex;
      flex-direction: column;
      justify-content: center; }
  .textblock .ctas {
    margin-top: 30px; }
  @media screen and (max-width: 80em) {
    .textblock {
      min-height: 800px; } }
  @media screen and (max-width: 62.5em) {
    .textblock.textblockNormal:not(.image-horizontal) .image {
      min-width: 260px;
      max-width: 260px;
      min-height: 350px;
      max-height: 350px; }
    .textblock.image-horizontal .image {
      width: 420px;
      height: 320px; }
    .textblock .text {
      margin-top: 20px; }
    .textblock .ctas {
      margin-top: 20px; } }
  @media screen and (max-width: 48em) {
    .textblock {
      min-height: auto; }
      .textblock.textblockNormal {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start; }
        .textblock.textblockNormal .image {
          max-width: unset !important;
          min-width: unset !important;
          margin-right: 0;
          width: 100%; }
        .textblock.textblockNormal .text-container {
          margin-top: 40px; } }
  @media screen and (min-width: 75em) {
    .textblock.textblockNormal:not(.single), .textblock.fiftyFiftyBlock:not(.single) {
      padding: 0 var(--spacing-nav) 0;
      min-height: unset;
      margin-top: 200px; }
    .textblock.textblockFullscreen {
      margin-top: 200px; } }

.newsitems {
  display: flex;
  width: 100%; }
  .newsitems-section {
    display: flex;
    margin-left: var(--spacing-nav); }
    .newsitems-section .left {
      min-width: 400px;
      margin-right: 120px; }
      .newsitems-section .left .cta {
        margin-top: 40px;
        width: max-content; }
      @media screen and (max-width: 37.5em) {
        .newsitems-section .left {
          min-width: unset; } }
    @media screen and (min-width: 118.75em) {
      .newsitems-section {
        margin-left: auto;
        width: 100%;
        max-width: 2100px; } }
    @media screen and (min-width: 75em) {
      .newsitems-section {
        margin-top: 200px;
        padding-left: 40px; } }
  .newsitems .newsitem {
    width: 100%;
    background-color: #F2F2F2;
    cursor: pointer;
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
    transition: clip-path .4s ease, width .4s ease, background-color .4s ease;
    color: #4D4D4D; }
    .newsitems .newsitem:nth-child(2n) {
      margin: 0 20px; }
    .newsitems .newsitem:last-child {
      margin-right: 0; }
    .newsitems .newsitem--figure {
      height: 550px;
      overflow: hidden; }
      .newsitems .newsitem--figure .image {
        height: 100%; }
      @media screen and (max-width: 90.625em) {
        .newsitems .newsitem--figure {
          height: 400px; } }
    .newsitems .newsitem--info {
      padding: 60px;
      padding-right: 100px;
      position: relative; }
      .newsitems .newsitem--info .arrow {
        position: absolute;
        right: 30px;
        top: 30px;
        opacity: 0;
        width: 40px;
        height: 40px;
        transition: opacity .4s ease; }
        .newsitems .newsitem--info .arrow svg {
          width: 100%;
          height: 100%; }
      .newsitems .newsitem--info .title {
        margin-top: 30px;
        margin-bottom: 0;
        hyphens: auto; }
      .newsitems .newsitem--info .text {
        margin-top: 30px; }
      .newsitems .newsitem--info > * {
        max-width: 400px;
        transition: color .4s ease; }
    .newsitems .newsitem:hover, .newsitems .newsitem:focus, .newsitems .newsitem:active {
      background-color: var(--primary-color);
      clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0);
      width: 120%;
      color: white; }
      .newsitems .newsitem:hover .newsitem--info .arrow, .newsitems .newsitem:focus .newsitem--info .arrow, .newsitems .newsitem:active .newsitem--info .arrow {
        opacity: 1; }
      .newsitems .newsitem:hover .newsitem--info > *, .newsitems .newsitem:focus .newsitem--info > *, .newsitems .newsitem:active .newsitem--info > * {
        color: white; }
    @media screen and (max-width: 103.75em) {
      .newsitems .newsitem--info {
        padding-right: 60px; }
      .newsitems .newsitem:hover, .newsitems .newsitem:focus, .newsitems .newsitem:active {
        clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0); } }
    @media screen and (max-width: 84.375em) {
      .newsitems .newsitem--info {
        padding-right: 40px;
        padding: 40px; }
      .newsitems .newsitem:hover, .newsitems .newsitem:focus, .newsitems .newsitem:active {
        clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0); } }
  @media screen and (max-width: 91.25em) {
    .newsitems-section .left {
      margin-right: 20px; } }
  @media screen and (max-width: 81.25em) {
    .newsitems {
      margin-top: 40px; }
      .newsitems-section {
        flex-direction: column;
        align-items: flex-start; }
        .newsitems-section .left {
          margin-right: 0px; } }
  @media screen and (max-width: 56.25em) {
    .newsitems {
      flex-direction: column; }
      .newsitems-section {
        margin-right: var(--spacing-nav); }
      .newsitems .newsitem--figure {
        height: 320px; }
      .newsitems .newsitem:hover {
        width: 100%; }
      .newsitems .newsitem + .newsitem {
        margin-left: 0;
        margin-top: 20px; } }
  @media screen and (max-width: 48em) {
    .newsitems-section {
      margin-top: 80px; }
      .newsitems-section .left .cta {
        margin-top: 20px; } }

.Footer {
  padding-top: 220px;
  min-height: 1080px; }
  .Footer .top {
    display: flex; }
    .Footer .top > * {
      width: 100%; }
    .Footer .top .info {
      display: flex;
      justify-content: center;
      padding: 190px 20px;
      background-color: #F2F2F2; }
      .Footer .top .info .contact {
        margin-right: 180px; }
        .Footer .top .info .contact .address p {
          margin-bottom: 0; }
        .Footer .top .info .contact-data {
          display: flex;
          flex-direction: column;
          margin-top: 20px; }
      .Footer .top .info h3 {
        color: var(--primary-color);
        margin-bottom: 40px; }
      .Footer .top .info a {
        color: #4D4D4D; }
        .Footer .top .info a:hover, .Footer .top .info a:active, .Footer .top .info a:focus {
          color: #4D4D4D; }
      .Footer .top .info .socials nav {
        display: flex;
        flex-direction: column; }
    .Footer .top .newsletter {
      margin-left: 20px;
      background-color: var(--primary-color);
      display: flex;
      justify-content: center;
      align-items: center; }
      .Footer .top .newsletter-content > h3 {
        color: white; }
      .Footer .top .newsletter .Form .ff-form-errors, .Footer .top .newsletter .Form .ff-errors, .Footer .top .newsletter .Form .Form-errors, .Footer .top .newsletter .Form .freeform-form-has-errors {
        color: #F2F2F2;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600; }
      .Footer .top .newsletter .Form .input-fields.email {
        margin-bottom: 20px; }
        .Footer .top .newsletter .Form .input-fields.email input {
          background-color: white;
          color: #4D4D4D; }
          .Footer .top .newsletter .Form .input-fields.email input::placeholder {
            color: #B3B3B3; }
        .Footer .top .newsletter .Form .input-fields.email label {
          display: none; }
      .Footer .top .newsletter .Form .input-fields.field-amount--2:not(.accept) > *:not(:last-child) {
        margin-right: 0;
        width: 470px; }
      .Footer .top .newsletter .Form .input-fields.field-amount--2 .Form-item--action {
        width: 90px;
        height: 90px; }
        .Footer .top .newsletter .Form .input-fields.field-amount--2 .Form-item--action button[type=submit] {
          height: 100%; }
          .Footer .top .newsletter .Form .input-fields.field-amount--2 .Form-item--action button[type=submit].cta {
            padding: 0;
            background-color: #4D4D4D;
            width: inherit;
            clip-path: none;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center; }
            .Footer .top .newsletter .Form .input-fields.field-amount--2 .Form-item--action button[type=submit].cta:after {
              content: url("data:image/svg+xml;charset=utf8,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_76_666)'%3E%3Cpath d='M53.3084 0L55.9976 2.68923L56 48.3939L48.3967 56V12.9821L8.94906 52.4315L3.57083 47.053L43.0185 7.60361H0L7.60328 0H53.3084Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_76_666'%3E%3Crect width='56' height='56' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
              position: absolute;
              width: 56px;
              height: 56px;
              transform: scale(0.9) rotate(45deg); }
        @media screen and (max-width: 48em) {
          .Footer .top .newsletter .Form .input-fields.field-amount--2 .Form-item--action {
            width: 70px;
            height: 70px; }
            .Footer .top .newsletter .Form .input-fields.field-amount--2 .Form-item--action button[type=submit].cta:after {
              transform: scale(0.6) rotate(45deg); } }
      .Footer .top .newsletter .Form .accept {
        align-items: center; }
        .Footer .top .newsletter .Form .accept input:checked ~ .fake-checkbox {
          background-color: #4D4D4D !important; }
        .Footer .top .newsletter .Form .accept .fake-checkbox {
          border-color: white;
          background-color: white; }
        .Footer .top .newsletter .Form .accept label > div {
          display: flex;
          white-space: nowrap;
          align-items: center;
          color: white; }
          .Footer .top .newsletter .Form .accept label > div > a {
            margin-left: 6px; }
  .Footer .bottom {
    min-height: 280px;
    padding: 120px 220px;
    display: flex;
    justify-content: space-between; }
    .Footer .bottom svg {
      max-width: 270px; }
      .Footer .bottom svg path {
        fill: #B3B3B3; }
    .Footer .bottom a {
      color: #B3B3B3;
      font-size: 18px; }
      .Footer .bottom a span {
        transition: color .4s ease; }
      .Footer .bottom a:hover span {
        color: var(--primary-color); }
  @media screen and (max-width: 100em) {
    .Footer .top .info {
      padding: 150px var(--spacing-nav); }
      .Footer .top .info .contact {
        margin-right: 100px; }
    .Footer .top .newsletter {
      padding: 20px var(--spacing-nav); } }
  @media screen and (max-width: 84.375em) {
    .Footer {
      min-height: unset; }
      .Footer .bottom {
        padding-left: var(--spacing-nav);
        padding-right: var(--spacing-nav); } }
  @media screen and (max-width: 78.75em) {
    .Footer .top .info {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 80px;
      padding-bottom: 80px; }
      .Footer .top .info .contact > h3, .Footer .top .info .socials > h3 {
        margin-bottom: 10px; }
      .Footer .top .info .socials {
        margin-top: 30px; } }
  @media screen and (max-width: 71.875em) {
    .Footer .top {
      flex-direction: column-reverse; }
      .Footer .top .info {
        flex-direction: row; }
        .Footer .top .info .socials {
          margin-top: 0; }
      .Footer .top .newsletter {
        margin-left: 0;
        padding-top: 60px;
        padding-bottom: 60px; }
        .Footer .top .newsletter .input-fields:last-of-type {
          margin-bottom: 0 !important; }
    .Footer .bottom {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      grid-template-areas: 'privacy debuf siteby';
      align-items: center; }
      .Footer .bottom > nav {
        grid-area: privacy;
        display: flex;
        flex-direction: column; }
      .Footer .bottom > .debuf {
        grid-area: debuf; }
      .Footer .bottom > .siteby {
        grid-area: siteby;
        margin-left: auto; } }
  @media screen and (max-width: 56.25em) {
    .Footer .bottom {
      padding-top: 180px;
      grid-template-columns: 1fr;
      grid-template-areas: 'debuf' 'privacy' 'siteby';
      padding-top: 60px;
      padding-bottom: 60px; }
      .Footer .bottom > .siteby {
        margin-left: 0; } }
  @media screen and (max-width: 48em) {
    .Footer {
      padding-top: 100px; }
      .Footer .top .info {
        justify-content: flex-start; }
        .Footer .top .info .contact {
          margin-right: 60px; }
      .Footer .top .newsletter {
        width: 100%; }
        .Footer .top .newsletter-content {
          width: 100%; }
          .Footer .top .newsletter-content .Form {
            width: 100%; }
            .Footer .top .newsletter-content .Form .input-fields.field-amount--2:not(.accept) {
              grid-template-columns: repeat(2, auto);
              width: 100%; }
              .Footer .top .newsletter-content .Form .input-fields.field-amount--2:not(.accept) > *:not(:last-child) {
                max-width: 470px;
                width: 100%; } }
  @media screen and (max-width: 37.5em) {
    .Footer .top .info {
      flex-wrap: wrap;
      margin-bottom: -30px; }
      .Footer .top .info > * {
        margin-bottom: 30px; } }
  @media screen and (max-width: 28.125em) {
    .Footer .accept.field-amount--2 {
      align-items: flex-start !important; }
      .Footer .accept.field-amount--2 .Form-controls > label > div {
        flex-direction: column;
        align-items: flex-start !important; }
        .Footer .accept.field-amount--2 .Form-controls > label > div a {
          margin-left: 0 !important; } }

.products-list .hidden {
  display: none; }

.products-list .product input[type=radio] {
  display: none; }
  .products-list .product input[type=radio]:checked ~ label .arrow {
    transform: rotate(180deg); }

.products-list .product label {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  transition: background-color .4s ease;
  border-top: 1px solid var(--primary-color);
  cursor: pointer; }
  .products-list .product label .label-content {
    padding: 65px var(--spacing-nav);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
    @media screen and (max-width: 48em) {
      .products-list .product label .label-content {
        padding: 35px var(--spacing-nav); } }
  .products-list .product label .product-info {
    height: 0px;
    overflow: hidden;
    transition: height .4s ease;
    background-color: var(--primary-color);
    display: grid;
    grid-template-columns: auto 50vw; }
    .products-list .product label .product-info .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: fit-content;
      padding-top: 160px;
      padding-bottom: 160px;
      padding-right: 80px; }
      .products-list .product label .product-info .left p {
        color: white; }
      .products-list .product label .product-info .left > div {
        max-width: 420px;
        margin-bottom: 50px; }
      .products-list .product label .product-info .left .cta {
        background-color: #4D4D4D; }
        .products-list .product label .product-info .left .cta:hover {
          background-color: #F4F4F4;
          color: #4D4D4D; }
      @media screen and (max-width: 48em) {
        .products-list .product label .product-info .left {
          padding: 100px var(--spacing-nav) !important; }
          .products-list .product label .product-info .left > div {
            margin-bottom: 20px; } }
      @media screen and (max-width: 37.5em) {
        .products-list .product label .product-info .left {
          padding: 60px var(--spacing-nav) !important; } }
    .products-list .product label .product-info .image {
      height: inherit;
      width: 100%; }
    @media screen and (max-width: 37.5em) {
      .products-list .product label .product-info {
        grid-template-columns: auto; }
        .products-list .product label .product-info .image {
          max-height: 300px; } }
  .products-list .product label h2 {
    color: var(--primary-color);
    transition: color .4s ease; }
  .products-list .product label .arrow {
    transition: transform .4s ease; }
    .products-list .product label .arrow svg path {
      fill: var(--primary-color);
      transition: fill .4s ease; }
    @media screen and (max-width: 37.5em) {
      .products-list .product label .arrow {
        width: 40px;
        display: flex;
        justify-content: center; } }

.products-list .product:last-of-type label {
  border-bottom: 1px solid var(--primary-color); }

.products-list .product:hover label, .products-list .product:hover ~ label, .products-list .product input[type=radio]:checked label, .products-list .product input[type=radio]:checked ~ label {
  background-color: var(--primary-color); }
  .products-list .product:hover label h2, .products-list .product:hover ~ label h2, .products-list .product input[type=radio]:checked label h2, .products-list .product input[type=radio]:checked ~ label h2 {
    color: white; }
  .products-list .product:hover label .arrow svg path, .products-list .product:hover ~ label .arrow svg path, .products-list .product input[type=radio]:checked label .arrow svg path, .products-list .product input[type=radio]:checked ~ label .arrow svg path {
    fill: white; }

.products-list .product.electric .label-content h2 {
  color: #209BD8;
  position: relative; }
  .products-list .product.electric .label-content h2 .electric-icon {
    position: absolute;
    left: -40px;
    height: 30px;
    display: flex;
    align-items: center;
    top: calc(50% - 15px); }
    .products-list .product.electric .label-content h2 .electric-icon svg path {
      fill: #209BD8;
      transition: fill .4s ease; }

.products-list .product.electric .label-content .arrow svg path {
  fill: #209BD8; }

.products-list .product.electric .product-info {
  background-color: #209BD8; }
  .products-list .product.electric .product-info .left p {
    color: white; }
  .products-list .product.electric .product-info .left .cta.orange {
    background-color: white;
    color: #209BD8; }
    .products-list .product.electric .product-info .left .cta.orange:hover {
      background-color: #F4F4F4;
      color: #209BD8; }

.products-list .product.electric:hover label, .products-list .product.electric:hover ~ label, .products-list .product.electric input[type=radio]:checked label, .products-list .product.electric input[type=radio]:checked ~ label {
  background-color: #209BD8; }
  .products-list .product.electric:hover label h2, .products-list .product.electric:hover ~ label h2, .products-list .product.electric input[type=radio]:checked label h2, .products-list .product.electric input[type=radio]:checked ~ label h2 {
    color: white; }
    .products-list .product.electric:hover label h2 .electric-icon svg path, .products-list .product.electric:hover ~ label h2 .electric-icon svg path, .products-list .product.electric input[type=radio]:checked label h2 .electric-icon svg path, .products-list .product.electric input[type=radio]:checked ~ label h2 .electric-icon svg path {
      fill: white; }
  .products-list .product.electric:hover label .arrow svg path, .products-list .product.electric:hover ~ label .arrow svg path, .products-list .product.electric input[type=radio]:checked label .arrow svg path, .products-list .product.electric input[type=radio]:checked ~ label .arrow svg path {
    fill: white; }

@media screen and (max-width: 48em) {
  .products-list .product.electric .label-content h2 {
    display: flex; }
    .products-list .product.electric .label-content h2 .electric-icon {
      position: relative;
      left: 0;
      margin-right: 20px; } }

.gallery-section {
  margin: 20px var(--spacing-nav); }
  .gallery-section .masonry-item {
    width: 50%;
    padding: 10px; }
    @media screen and (max-width: 48em) {
      .gallery-section .masonry-item {
        width: 100%;
        padding: 5px 0; } }
    @media screen and (min-width: 48em) {
      .gallery-section .masonry-item:nth-of-type(2) {
        margin-top: -330px; } }
  .gallery-section .images {
    max-width: 2200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px; }
    .gallery-section .images-item.hide {
      display: none; }
    .gallery-section .images-item .info {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
      transition: clip-path .4s ease;
      z-index: 1;
      display: flex;
      align-items: flex-end;
      padding: 50px; }
      .gallery-section .images-item .info:before {
        content: '';
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: var(--primary-color);
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity .4s ease; }
      .gallery-section .images-item .info-container {
        position: relative;
        height: inherit; }
      .gallery-section .images-item .info h2 {
        color: white;
        user-select: none;
        pointer-events: none;
        position: relative;
        z-index: 1; }
      .gallery-section .images-item .info .arrow {
        user-select: none;
        pointer-events: none;
        position: absolute;
        right: 50px;
        top: 50px;
        width: 50px;
        height: 50px;
        opacity: 0;
        transition: opacity .4s ease; }
        .gallery-section .images-item .info .arrow svg {
          width: 100%;
          height: 100%; }
      .gallery-section .images-item .info ~ .image {
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
        transition: clip-path .4s ease; }
      .gallery-section .images-item .info:hover {
        clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0); }
        .gallery-section .images-item .info:hover ~ .image {
          clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0); }
        .gallery-section .images-item .info:hover:before, .gallery-section .images-item .info:hover .arrow {
          opacity: 1; }
      @media screen and (max-width: 28.125em) {
        .gallery-section .images-item .info {
          pointer-events: none; } }
    @media screen and (max-width: 28.125em) {
      .gallery-section .images {
        margin-top: 10px; } }
  @media screen and (max-width: 28.125em) {
    .gallery-section {
      margin-top: 10px;
      margin-bottom: 10px; } }

.cta-list {
  display: flex;
  flex-direction: column; }
  .cta-list--item {
    background-color: #F2F2F2;
    border-top: 1px solid var(--primary-color);
    cursor: pointer;
    transition: background-color .4s ease; }
    .cta-list--item .container {
      padding: 65px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
    .cta-list--item:last-of-type {
      border-bottom: 1px solid var(--primary-color); }
    .cta-list--item .arrow {
      transform: rotate(45deg);
      opacity: 0;
      transition: opacity .4s ease; }
    .cta-list--item h2 {
      color: var(--primary-color);
      transition: color .4s ease; }
    .cta-list--item:hover {
      opacity: 1;
      background-color: var(--primary-color); }
      .cta-list--item:hover h2 {
        color: white; }
      .cta-list--item:hover .arrow {
        opacity: 1; }
    @media screen and (max-width: 100em) {
      .cta-list--item .container {
        padding-left: var(--spacing-nav);
        padding-right: var(--spacing-nav); } }
    @media screen and (max-width: 75em) {
      .cta-list--item .container {
        padding: 35px var(--spacing-nav); }
        .cta-list--item .container .arrow {
          transform: scale(0.7) rotate(45deg); }
        .cta-list--item .container h2 {
          hyphens: auto;
          word-break: break-word; } }
    @media screen and (max-width: 37.5em) {
      .cta-list--item .container {
        padding: 15px var(--spacing-nav); }
        .cta-list--item .container .arrow {
          transform: scale(0.5) rotate(45deg); }
        .cta-list--item .container h2 {
          hyphens: auto; } }

form .ff-form-errors, form .ff-errors, form .Form-errors, form .freeform-form-has-errors {
  color: #ff1e00;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600; }
  @media screen and (max-width: 37.5em) {
    form .ff-form-errors, form .ff-errors, form .Form-errors, form .freeform-form-has-errors {
      font-size: 16px !important;
      line-height: 24px; }
      form .ff-form-errors *, form .ff-errors *, form .Form-errors *, form .freeform-form-has-errors * {
        font-size: inherit;
        line-height: inherit; } }

form .ff-errors, form .Form-errors, form .freeform-form-has-errors {
  margin-top: 10px;
  padding-left: 0; }
  form .ff-errors ul, form .Form-errors ul, form .freeform-form-has-errors ul {
    padding-left: 0; }
  form .ff-errors li, form .Form-errors li, form .freeform-form-has-errors li {
    margin-bottom: 0;
    list-style: none; }

form .ff-form-success {
  color: #49e81d;
  font-weight: bold;
  max-width: 320px; }
  form .ff-form-success ~ * {
    display: none; }

form .active-field label {
  opacity: 1 !important; }

form .active-field input {
  padding-top: 25px !important;
  padding-bottom: 7px !important;
  border-color: #4D4D4D !important; }

form .download-submit--btn {
  opacity: 0;
  pointer-events: none;
  position: absolute; }

form .freeform-column {
  position: relative; }

form .input-fields {
  margin-bottom: 40px; }
  form .input-fields .file-label .cta {
    transition: background-color .4s ease, color .4s ease; }
  form .input-fields .file-label .remove-file {
    opacity: 0;
    transition: opacity .4s ease;
    pointer-events: none;
    margin-right: 10px;
    display: none;
    align-items: center; }
  form .input-fields .file-label.upload-ready .cta {
    background-color: var(--primary-color);
    color: white; }
  form .input-fields .file-label.upload-ready .remove-file {
    opacity: 1;
    pointer-events: all;
    display: flex; }
  form .input-fields.html .line {
    background-color: var(--primary-color);
    width: 100%;
    height: 1px; }
  form .input-fields .Form-controls {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column; }
    form .input-fields .Form-controls.Form-item--action {
      align-items: flex-end; }
      form .input-fields .Form-controls.Form-item--action button {
        font-size: 22px;
        line-height: 30px; }
        @media screen and (max-width: 48em) {
          form .input-fields .Form-controls.Form-item--action button {
            font-size: 18px;
            line-height: 26px; } }
  form .input-fields .Form-label {
    color: #B3B3B3;
    margin-bottom: 10px; }
  form .input-fields .Form-instructions {
    display: none; }
  form .input-fields p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px; }
    form .input-fields p strong {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600; }
  form .input-fields.submit {
    margin-top: 40px; }
  form .input-fields.html {
    margin-top: 30px; }
  form .input-fields.accept {
    display: flex; }
    form .input-fields.accept label {
      cursor: pointer;
      font-size: 16px;
      color: #B3B3B3;
      line-height: 1;
      padding-left: 10px; }
      form .input-fields.accept label a {
        font-size: inherit;
        color: inherit;
        text-decoration: underline; }
    form .input-fields.accept p {
      font-size: 18px;
      line-height: 28px; }
    form .input-fields.accept a {
      color: #4D4D4D; }
    form .input-fields.accept input {
      display: none; }
      form .input-fields.accept input:checked ~ .fake-checkbox {
        background-color: var(--primary-color); }
    form .input-fields.accept .fake-checkbox {
      border: 2px solid #B3B3B3;
      display: flex;
      width: 20px;
      height: 20px;
      transition: background-color .4s ease;
      cursor: pointer; }
    @media screen and (max-width: 37.5em) {
      form .input-fields.accept {
        grid-template-columns: 25px 1fr !important;
        grid-template-areas: 'checkbox privacy' 'submit submit'; }
        form .input-fields.accept > *:nth-child(1) {
          grid-area: checkbox; }
        form .input-fields.accept > *:nth-child(2) {
          grid-area: privacy; }
        form .input-fields.accept > *:nth-child(3) {
          grid-area: submit;
          align-items: flex-start;
          margin-top: 10px; } }
  form .input-fields.select {
    position: relative; }
    form .input-fields.select:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 10px;
      width: 10px;
      height: 10px; }
    form .input-fields.select select {
      cursor: pointer;
      position: relative; }
  form .input-fields select {
    background: var(--primary-color) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCA1MSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzY2XzQ0NykiPgo8cGF0aCBkPSJNNTAuNTUzMiAwTDI5LjQ4MyAyMS4yNTk1TDI2Ljc2NDcgMjRIMjMuNzkwNEwyMS4wNzIxIDIxLjI1NzVMMCAwSDguNDEwODlMMjUuMjc2NiAxNy4wMTU3TDQyLjE0MjMgMEg1MC41NTMyWiIgZmlsbD0iI0ZGNTAwMCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzY2XzQ0NyI+CjxyZWN0IHdpZHRoPSI1MC41NTMyIiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=") no-repeat;
    background-position: right 40px top 50%; }
  form .input-fields input[type=file], form .input-fields textarea[type=file] {
    display: none; }
  form .input-fields input:not([type=radio]), form .input-fields input:not(.readonly), form .input-fields textarea:not([type=radio]), form .input-fields textarea:not(.readonly) {
    transition: background-color .4s ease, color .4s ease; }
    form .input-fields input:not([type=radio]):focus, form .input-fields input:not(.readonly):focus, form .input-fields textarea:not([type=radio]):focus, form .input-fields textarea:not(.readonly):focus {
      background-color: var(--primary-color);
      color: white; }
  form .input-fields textarea {
    resize: none;
    height: 270px !important; }
    @media screen and (max-width: 48em) {
      form .input-fields textarea {
        height: 200px !important; } }
  form .input-fields select, form .input-fields input, form .input-fields textarea {
    appearance: none;
    outline: none;
    color: #4D4D4D;
    font-size: 22px;
    line-height: 30px;
    border-radius: 0;
    border: 1px solid #F2F2F2;
    background-color: #F2F2F2;
    padding: 20px 40px;
    height: 90px;
    display: flex;
    align-items: center;
    width: 100%; }
    form .input-fields select::placeholder, form .input-fields input::placeholder, form .input-fields textarea::placeholder {
      color: #4D4D4D; }
    @media screen and (max-width: 48em) {
      form .input-fields select, form .input-fields input, form .input-fields textarea {
        font-size: 18px;
        padding: 15px 25px;
        height: 70px; } }
  form .input-fields *:not(.cta) {
    font-family: "Shapiro 45", sans-serif; }
  form .input-fields.field-amount--1 {
    width: 100%; }
  form .input-fields.field-amount--2:not(.accept) {
    display: grid;
    grid-template-columns: auto 1fr; }
    form .input-fields.field-amount--2:not(.accept) > *:not(:last-child) {
      margin-right: 20px; }
  form .input-fields.field-amount--3 {
    display: grid;
    grid-template-columns: 50% repeat(2, 25%); }
    form .input-fields.field-amount--3.accept {
      grid-template-columns: 20px repeat(2, auto); }
    form .input-fields.field-amount--3 > *:not(:last-child) {
      margin-right: 20px; }
  @media screen and (max-width: 37.5em) {
    form .input-fields {
      margin-bottom: 20px; } }

@media screen and (max-width: 37.5em) {
  form .field-amount--3:not(.accept) {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-template-areas: "street street" "number addition"; }
    form .field-amount--3:not(.accept) > *:nth-child(1) {
      grid-area: street;
      margin-right: 0 !important;
      margin-bottom: 20px; }
    form .field-amount--3:not(.accept) > *:nth-child(2) {
      grid-area: number; }
    form .field-amount--3:not(.accept) > *:nth-child(3) {
      grid-area: addition; } }

@media screen and (max-width: 25em) {
  form .field-amount--2:not(.email) {
    grid-template-columns: 1fr !important; }
  form .field-amount--2 > *:nth-child(1) {
    margin-right: 0 !important;
    margin-bottom: 20px; }
  form .field-amount--3 {
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-areas: "street" "number" "addition"; }
    form .field-amount--3 > *:nth-child(2) {
      margin-right: 0 !important;
      margin-bottom: 20px; } }

form button {
  cursor: pointer; }

.tweedehandsitems {
  display: flex; }
  .tweedehandsitems-section {
    display: flex;
    margin-left: var(--spacing-nav); }
    .tweedehandsitems-section .left {
      min-width: 400px;
      margin-right: 120px; }
      .tweedehandsitems-section .left .cta {
        margin-top: 40px;
        width: max-content; }
  .tweedehandsitems .tweedehandsitem {
    width: 100%;
    background-color: #F2F2F2;
    transition: width .4s ease, background-color .4s ease;
    color: #4D4D4D;
    margin: 10px;
    position: relative; }
    .tweedehandsitems .tweedehandsitem:last-child {
      margin-right: 0; }
    .tweedehandsitems .tweedehandsitem--figure {
      height: 550px;
      overflow: hidden; }
      .tweedehandsitems .tweedehandsitem--figure .image {
        height: 100%; }
      @media screen and (max-width: 90.625em) {
        .tweedehandsitems .tweedehandsitem--figure {
          height: 400px; } }
    .tweedehandsitems .tweedehandsitem--info {
      padding: 60px;
      padding-right: 100px;
      padding-bottom: 100px; }
      .tweedehandsitems .tweedehandsitem--info .title {
        margin-top: 30px;
        margin-bottom: 0;
        hyphens: auto; }
      .tweedehandsitems .tweedehandsitem--info .text {
        margin-top: 30px; }
      .tweedehandsitems .tweedehandsitem--info > * {
        max-width: 400px;
        transition: color .4s ease; }
      .tweedehandsitems .tweedehandsitem--info-container .contact {
        text-align: center;
        opacity: 0;
        transition: opacity .4s ease;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 30px;
        color: #4D4D4D;
        text-decoration: underline; }
    .tweedehandsitems .tweedehandsitem:hover, .tweedehandsitems .tweedehandsitem:focus, .tweedehandsitems .tweedehandsitem:active {
      background-color: var(--primary-color);
      width: 120%;
      color: white; }
      .tweedehandsitems .tweedehandsitem:hover .tweedehandsitem--info .arrow, .tweedehandsitems .tweedehandsitem:focus .tweedehandsitem--info .arrow, .tweedehandsitems .tweedehandsitem:active .tweedehandsitem--info .arrow {
        opacity: 1; }
      .tweedehandsitems .tweedehandsitem:hover .tweedehandsitem--info > *, .tweedehandsitems .tweedehandsitem:focus .tweedehandsitem--info > *, .tweedehandsitems .tweedehandsitem:active .tweedehandsitem--info > * {
        color: white; }
      .tweedehandsitems .tweedehandsitem:hover .tweedehandsitem--info-container .contact, .tweedehandsitems .tweedehandsitem:focus .tweedehandsitem--info-container .contact, .tweedehandsitems .tweedehandsitem:active .tweedehandsitem--info-container .contact {
        opacity: 1; }

.video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; }
  .video-container img, .video-container video {
    width: 100%;
    height: 100%;
    max-height: inherit;
    object-fit: cover; }
  .video-container video {
    width: 100%;
    height: 820px; }
    .video-container video:hover ~ .play-button-wrapper .circle-play-b {
      opacity: 1 !important; }
    @media screen and (max-width: 87.5em) {
      .video-container video {
        height: 500px; } }
    @media screen and (max-width: 68.75em) {
      .video-container video {
        height: 400px; } }
    @media screen and (max-width: 37.5em) {
      .video-container video {
        height: 250px; } }
  .video-container .play-button-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    pointer-events: none;
    z-index: 1; }
    .video-container .play-button-wrapper .circle-play-b {
      cursor: pointer;
      pointer-events: all;
      transition: opacity .2s ease; }
      .video-container .play-button-wrapper .circle-play-b:before {
        content: '';
        background-color: rgba(77, 77, 77, 0.4);
        transition: background-color .2s ease;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0; }
  @media screen and (max-width: 37.5em) {
    .video-container .play-button-wrapper svg {
      width: 50px; } }

.downloads {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  width: 100%;
  max-width: 600px; }
  .downloads > h3 {
    margin-bottom: 30px; }
  .downloads .download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F2F2F2;
    padding: 10px 20px;
    border: 1px solid var(--primary-color);
    border-left: none;
    border-right: none; }
    .downloads .download > * {
      color: var(--primary-color);
      transition: color .4s ease; }
    .downloads .download:not(:first-of-type) {
      border-top: none; }
    .downloads .download:hover {
      background-color: var(--primary-color); }
      .downloads .download:hover > * {
        color: white; }
  .downloads-container {
    margin-top: 100px; }
    @media screen and (max-width: 37.5em) {
      .downloads-container {
        margin-top: 60px; } }

.ProductenPage .title.container {
  margin-top: 120px;
  margin-bottom: 180px;
  padding: 0 var(--spacing-nav); }
  @media screen and (max-width: 37.5em) {
    .ProductenPage .title.container {
      margin-top: 40px;
      margin-bottom: 60px; } }

.ProductenPage .product-detail .products-list {
  margin-top: 80px; }

.ProductenPage .product-detail .intro-outro-textblock {
  min-height: 850px;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin: 0 var(--spacing-nav); }
  .ProductenPage .product-detail .intro-outro-textblock .text {
    margin-top: 50px; }
    .ProductenPage .product-detail .intro-outro-textblock .text-container {
      max-width: 580px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      padding: 0 40px; }
      .ProductenPage .product-detail .intro-outro-textblock .text-container .downloads {
        margin-top: 100px;
        margin-bottom: 0px; }
        @media screen and (max-width: 37.5em) {
          .ProductenPage .product-detail .intro-outro-textblock .text-container .downloads {
            margin-top: 40px; } }
  .ProductenPage .product-detail .intro-outro-textblock .cta {
    margin-top: 50px; }
  .ProductenPage .product-detail .intro-outro-textblock.intro .image {
    padding-left: 10px; }
  .ProductenPage .product-detail .intro-outro-textblock.outro .image {
    padding-right: 10px; }
  @media screen and (max-width: 100em) {
    .ProductenPage .product-detail .intro-outro-textblock {
      min-height: 650px; } }
  @media screen and (max-width: 56.25em) {
    .ProductenPage .product-detail .intro-outro-textblock {
      min-height: 500px; } }
  @media screen and (max-width: 48em) {
    .ProductenPage .product-detail .intro-outro-textblock {
      display: flex;
      flex-direction: column; }
      .ProductenPage .product-detail .intro-outro-textblock .text {
        margin-top: 10px; }
        .ProductenPage .product-detail .intro-outro-textblock .text-container {
          padding: 0;
          margin-right: 0;
          margin-left: 0; }
      .ProductenPage .product-detail .intro-outro-textblock .cta {
        margin-top: 10px; }
      .ProductenPage .product-detail .intro-outro-textblock.intro .image {
        margin-top: 40px;
        padding-left: 0; }
      .ProductenPage .product-detail .intro-outro-textblock.outro .image {
        margin-bottom: 20px;
        padding-right: 0; } }

.OverOnsPage .textblocks {
  margin-top: 200px; }
  @media screen and (max-width: 48em) {
    .OverOnsPage .textblocks {
      margin-top: 100px; } }
  @media screen and (max-width: 37.5em) {
    .OverOnsPage .textblocks {
      margin-top: 80px; } }

.OverOnsPage .timeline {
  grid-area: moments;
  width: 840px; }
  .OverOnsPage .timeline .moment {
    max-width: 660px;
    display: flex;
    align-items: center; }
    .OverOnsPage .timeline .moment .year {
      max-width: 280px;
      min-width: 280px; }
  .OverOnsPage .timeline .moment + .moment {
    margin-top: 60px; }
  .OverOnsPage .timeline-section {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 220px; }
    .OverOnsPage .timeline-section .left {
      padding-top: 200px;
      padding-right: 100px; }
      .OverOnsPage .timeline-section .left .text {
        margin-top: 50px;
        max-width: 420px; }
        .OverOnsPage .timeline-section .left .text-container {
          min-width: 720px;
          position: sticky;
          top: 200px; }
          .OverOnsPage .timeline-section .left .text-container h2 {
            color: var(--primary-color); }
          @media screen and (max-width: 121.875em) {
            .OverOnsPage .timeline-section .left .text-container {
              min-width: 520px; } }
    .OverOnsPage .timeline-section .right {
      display: flex;
      flex-direction: column; }
      .OverOnsPage .timeline-section .right .image {
        margin-right: 200px;
        width: 840px;
        height: 680px; }
        .OverOnsPage .timeline-section .right .image img {
          max-height: inherit;
          min-height: inherit;
          max-width: inherit;
          min-width: inherit; }
        @media screen and (max-width: 121.875em) {
          .OverOnsPage .timeline-section .right .image {
            margin-right: 0; } }
    @media screen and (max-width: 87.5em) {
      .OverOnsPage .timeline-section .left {
        padding-right: 40px; } }
  .OverOnsPage .timeline-container {
    margin-top: 80px;
    justify-content: space-between; }
  @media screen and (max-width: 93.75em) {
    .OverOnsPage .timeline {
      width: 640px; }
      .OverOnsPage .timeline-section .left {
        padding-top: 120px; }
        .OverOnsPage .timeline-section .left .text-container {
          top: 120px; }
      .OverOnsPage .timeline-section .right .image {
        width: 680px;
        height: 520px; } }
  @media screen and (max-width: 80em) {
    .OverOnsPage .timeline {
      width: 500px; }
      .OverOnsPage .timeline-section .left {
        padding-top: 80px; }
        .OverOnsPage .timeline-section .left .text-container {
          top: 80px;
          min-width: 470px; }
      .OverOnsPage .timeline-section .right .image {
        width: 520px;
        height: 560px; }
      .OverOnsPage .timeline .moment {
        max-width: 570px; }
        .OverOnsPage .timeline .moment .year {
          max-width: 180px;
          min-width: 180px; } }
  @media screen and (max-width: 66.25em) {
    .OverOnsPage .timeline {
      width: 500px; }
      .OverOnsPage .timeline-section .left .text-container {
        min-width: 340px;
        max-width: 340px;
        margin-right: 60px; }
      .OverOnsPage .timeline .moment {
        max-width: 570px; }
        .OverOnsPage .timeline .moment .year {
          max-width: 180px;
          min-width: 180px; } }
  @media screen and (max-width: 62.5em) {
    .OverOnsPage .timeline {
      width: 100%; }
      .OverOnsPage .timeline .moment {
        max-width: unset; }
      .OverOnsPage .timeline-section {
        flex-direction: column; }
        .OverOnsPage .timeline-section .left {
          position: relative;
          padding-top: 0;
          padding-right: 0; }
          .OverOnsPage .timeline-section .left .text-container {
            top: 0;
            min-width: unset;
            max-width: unset; }
        .OverOnsPage .timeline-section .right {
          margin-top: 50px; }
          .OverOnsPage .timeline-section .right .image {
            width: 100%;
            max-width: 850px; } }
  @media screen and (max-width: 48em) {
    .OverOnsPage .timeline-section {
      margin-top: 100px; } }
  @media screen and (max-width: 37.5em) {
    .OverOnsPage .timeline .moment {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
      .OverOnsPage .timeline .moment .year {
        margin-bottom: 10px;
        min-width: unset;
        max-width: unset; }
    .OverOnsPage .timeline .moment + .moment {
      margin-top: 0px; }
    .OverOnsPage .timeline-container {
      margin-top: 60px; }
    .OverOnsPage .timeline-section {
      margin-top: 80px; }
      .OverOnsPage .timeline-section .left .text {
        margin-top: 20px; }
      .OverOnsPage .timeline-section .right {
        margin-top: 30px; } }

.ContactPage .headerblock .cta {
  background-color: var(--primary-color);
  color: white; }
  .ContactPage .headerblock .cta:hover {
    background-color: #F4F4F4;
    color: #4D4D4D; }

@media screen and (min-width: 48em) and (max-width: 62.5em) {
  .ContactPage .headerblock .image {
    min-width: 350px !important;
    max-width: 350px !important;
    min-height: 440px !important;
    max-height: 440px !important; } }

@media screen and (max-width: 37.5em) {
  .ContactPage .headerblock {
    padding-top: 0 !important; } }

.ContactPage .form-container {
  display: flex;
  margin-top: 180px; }
  .ContactPage .form-container .left {
    max-width: 560px;
    width: 100%;
    margin-right: 200px; }
  .ContactPage .form-container .right {
    width: 100%; }
  @media screen and (max-width: 118.75em) {
    .ContactPage .form-container .left {
      margin-right: 140px; } }
  @media screen and (max-width: 80em) {
    .ContactPage .form-container .left {
      max-width: 400px;
      margin-right: 60px; } }
  @media screen and (max-width: 56.25em) {
    .ContactPage .form-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 100px; }
      .ContactPage .form-container .left {
        margin-bottom: 40px; } }

.JobsOverviewPage .title {
  margin-top: 120px;
  margin-bottom: 180px; }
  @media screen and (max-width: 48em) {
    .JobsOverviewPage .title {
      margin-top: 60px;
      margin-bottom: 100px; } }
  @media screen and (max-width: 37.5em) {
    .JobsOverviewPage .title {
      margin-top: 40px;
      margin-bottom: 60px; } }

.JobsOverviewPage .textblocks.container {
  margin-top: 140px; }
  .JobsOverviewPage .textblocks.container .textblock {
    padding: 0; }

.JobsPage .title {
  margin-top: 120px;
  margin-bottom: 180px; }
  .JobsPage .title-container {
    background-color: var(--primary-color); }
    .JobsPage .title-container h2 {
      color: white;
      padding: 60px 0; }
  @media screen and (max-width: 48em) {
    .JobsPage .title {
      margin-top: 60px;
      margin-bottom: 100px; } }
  @media screen and (max-width: 37.5em) {
    .JobsPage .title {
      margin-top: 40px;
      margin-bottom: 60px; }
      .JobsPage .title-container h2 {
        padding: 30px 0; } }

.JobsPage .jobinfo-item {
  width: 100%;
  display: flex;
  padding: 60px 0; }
  .JobsPage .jobinfo-item .left {
    display: flex;
    flex-direction: column;
    width: 50%; }
    .JobsPage .jobinfo-item .left h3 {
      max-width: 340px; }
    .JobsPage .jobinfo-item .left > * {
      max-width: 420px; }
    .JobsPage .jobinfo-item .left .text {
      margin-top: 30px; }
    .JobsPage .jobinfo-item .left .ctas {
      display: flex;
      flex-direction: column;
      margin-top: 50px; }
      .JobsPage .jobinfo-item .left .ctas .cta {
        width: max-content; }
      .JobsPage .jobinfo-item .left .ctas .cta + .cta {
        margin-left: 0; }
  .JobsPage .jobinfo-item .right {
    width: 50%; }
    .JobsPage .jobinfo-item .right > * {
      width: 70%; }
      .JobsPage .jobinfo-item .right > *.Form {
        width: 100%; }
    .JobsPage .jobinfo-item .right ul {
      margin-bottom: 0; }
      .JobsPage .jobinfo-item .right ul li + li {
        margin-top: 40px; }
  .JobsPage .jobinfo-item.has-line {
    border-top: 1px solid var(--primary-color); }
  @media screen and (max-width: 84.375em) {
    .JobsPage .jobinfo-item .left {
      width: 40%;
      padding-right: 60px; }
    .JobsPage .jobinfo-item .right {
      width: 60%; } }
  @media screen and (max-width: 71.25em) {
    .JobsPage .jobinfo-item .right .file-label .cta {
      padding: 25px 45px 25px 30px; } }
  @media screen and (max-width: 58.125em) {
    .JobsPage .jobinfo-item {
      flex-direction: column; }
      .JobsPage .jobinfo-item .left {
        width: 100%;
        padding-right: 0; }
        .JobsPage .jobinfo-item .left .text, .JobsPage .jobinfo-item .left .ctas {
          margin-top: 0; }
      .JobsPage .jobinfo-item .right {
        width: 100%; }
        .JobsPage .jobinfo-item .right > * {
          width: 100%; }
        .JobsPage .jobinfo-item .right .Form {
          margin-top: 60px; }
          .JobsPage .jobinfo-item .right .Form .file {
            display: flex;
            flex-direction: column; }
            .JobsPage .jobinfo-item .right .Form .file-label .cta {
              padding: 25px 45px 25px 30px; }
            .JobsPage .jobinfo-item .right .Form .file .Form-controls:nth-of-type(2) {
              margin-top: 20px; } }

.JobsPage .jobinfo-container {
  margin: 80px 0; }
  @media screen and (max-width: 37.5em) {
    .JobsPage .jobinfo-container {
      margin: 0; } }

.NieuwsPage .newsitems {
  padding: 0 var(--spacing-nav);
  display: flex;
  justify-content: center; }
  .NieuwsPage .newsitems-container {
    display: flex;
    flex-direction: column;
    max-width: 1860px; }
    @media screen and (min-width: 84.375em) {
      .NieuwsPage .newsitems-container.even {
        display: none; } }
    @media screen and (min-width: 56.25em) and (max-width: 84.375em) {
      .NieuwsPage .newsitems-container.odd {
        display: none; }
      .NieuwsPage .newsitems-container.even {
        display: flex; } }
    @media screen and (max-width: 56.25em) {
      .NieuwsPage .newsitems-container.odd {
        display: flex;
        flex-direction: column; }
      .NieuwsPage .newsitems-container.even {
        display: none; } }
  .NieuwsPage .newsitems .newsitem {
    width: 560px;
    margin: 10px !important; }
    .NieuwsPage .newsitems .newsitem:hover {
      width: 660px; }
  @media screen and (max-width: 56.25em) {
    .NieuwsPage .newsitems {
      align-items: center;
      margin-top: 0; }
      .NieuwsPage .newsitems .newsitem {
        max-width: 560px;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important; }
        .NieuwsPage .newsitems .newsitem--info {
          padding: 30px; }
        .NieuwsPage .newsitems .newsitem .text {
          margin-top: 20px;
          padding-right: 40px; }
        .NieuwsPage .newsitems .newsitem:hover {
          width: 100%; } }

.NieuwsPage .pagination {
  padding-top: 200px;
  display: flex;
  justify-content: center; }
  .NieuwsPage .pagination .pages {
    display: flex;
    justify-content: center; }
    .NieuwsPage .pagination .pages.reversed {
      flex-direction: row-reverse; }
    .NieuwsPage .pagination .pages .page {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px; }
      .NieuwsPage .pagination .pages .page.active h3 {
        color: var(--primary-color); }
  @media screen and (max-width: 48em) {
    .NieuwsPage .pagination {
      padding-top: 100px; } }
  @media screen and (max-width: 37.5em) {
    .NieuwsPage .pagination {
      padding-top: 60px; }
      .NieuwsPage .pagination .pages .page {
        width: 20px;
        height: 20px; } }

.NieuwsberichtenPage .header {
  margin-top: 20px; }

.NieuwsberichtenPage .news-info {
  margin-top: 80px; }
  .NieuwsberichtenPage .news-info h2 {
    margin-top: 20px; }
  .NieuwsberichtenPage .news-info .intro {
    margin-top: 60px; }

@media screen and (max-width: 37.5em) {
  .NieuwsberichtenPage .news-info {
    margin-top: 30px; }
    .NieuwsberichtenPage .news-info .intro {
      margin-top: 30px; } }

.NieuwsberichtenPage .titletextimageblocks {
  margin-top: 80px; }
  .NieuwsberichtenPage .titletextimageblocks .titletextimageblock .image-container {
    height: auto; }
    .NieuwsberichtenPage .titletextimageblocks .titletextimageblock .image-container:not(.contain) {
      max-height: 500px; }
      .NieuwsberichtenPage .titletextimageblocks .titletextimageblock .image-container:not(.contain) * {
        max-height: inherit; }
  .NieuwsberichtenPage .titletextimageblocks .titletextimageblock .text {
    font-size: 16px;
    line-height: 28px; }
    .NieuwsberichtenPage .titletextimageblocks .titletextimageblock .text * {
      font-size: inherit;
      line-height: inherit; }
  .NieuwsberichtenPage .titletextimageblocks .titletextimageblock + .titletextimageblock {
    margin-top: 60px; }
  @media screen and (max-width: 37.5em) {
    .NieuwsberichtenPage .titletextimageblocks {
      margin-top: 50px; }
      .NieuwsberichtenPage .titletextimageblocks h3 {
        margin-bottom: 10px; }
      .NieuwsberichtenPage .titletextimageblocks .titletextimageblock + .titletextimageblock {
        margin-top: 40px; } }

.NieuwsberichtenPage .share {
  margin-top: 80px;
  display: flex;
  flex-direction: column; }
  .NieuwsberichtenPage .share p {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 0; }
  .NieuwsberichtenPage .share .options {
    display: flex;
    margin-top: 20px; }
    .NieuwsberichtenPage .share .options a {
      height: 30px; }
      .NieuwsberichtenPage .share .options a svg {
        height: inherit; }
        .NieuwsberichtenPage .share .options a svg path {
          transition: fill .4s ease; }
      .NieuwsberichtenPage .share .options a:hover svg path {
        fill: var(--primary-color); }
    .NieuwsberichtenPage .share .options a + a {
      margin-left: 10px; }
  @media screen and (max-width: 37.5em) {
    .NieuwsberichtenPage .share {
      margin-top: 40px; } }

.NieuwsberichtenPage .back-btn {
  margin-top: 60px; }
  .NieuwsberichtenPage .back-btn .cta {
    width: fit-content; }
  @media screen and (max-width: 37.5em) {
    .NieuwsberichtenPage .back-btn {
      margin-top: 40px; } }

.TweedehandsPage .tweedehandsitems {
  padding: 0 var(--spacing-nav);
  display: flex;
  justify-content: center; }
  .TweedehandsPage .tweedehandsitems-container {
    display: flex;
    flex-direction: column;
    max-width: 1860px; }
    @media screen and (min-width: 84.375em) {
      .TweedehandsPage .tweedehandsitems-container.even {
        display: none; } }
    @media screen and (min-width: 56.25em) and (max-width: 84.375em) {
      .TweedehandsPage .tweedehandsitems-container.odd {
        display: none; }
      .TweedehandsPage .tweedehandsitems-container.even {
        display: flex; } }
    @media screen and (max-width: 56.25em) {
      .TweedehandsPage .tweedehandsitems-container.odd {
        display: flex; }
        .TweedehandsPage .tweedehandsitems-container.odd .tweedehandsitems {
          flex-direction: column;
          align-items: center; }
      .TweedehandsPage .tweedehandsitems-container.even {
        display: none; } }
  .TweedehandsPage .tweedehandsitems .tweedehandsitem {
    width: 560px;
    margin: 10px !important; }
    .TweedehandsPage .tweedehandsitems .tweedehandsitem:hover {
      width: 660px; }
  @media screen and (max-width: 56.25em) {
    .TweedehandsPage .tweedehandsitems {
      align-items: center;
      margin-top: 0; }
      .TweedehandsPage .tweedehandsitems .tweedehandsitem {
        max-width: 560px;
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important; }
        .TweedehandsPage .tweedehandsitems .tweedehandsitem--info {
          padding: 30px; }
        .TweedehandsPage .tweedehandsitems .tweedehandsitem .text {
          margin-top: 20px;
          padding-right: 40px; }
        .TweedehandsPage .tweedehandsitems .tweedehandsitem:hover {
          width: 100%; } }
  @media screen and (max-width: 37.5em) {
    .TweedehandsPage .tweedehandsitems .tweedehandsitem--figure {
      height: 320px; }
    .TweedehandsPage .tweedehandsitems .tweedehandsitem--info {
      transition: padding-bottom .4s ease; }
      .TweedehandsPage .tweedehandsitems .tweedehandsitem--info .title {
        margin-top: 0; }
    .TweedehandsPage .tweedehandsitems .tweedehandsitem:hover .tweedehandsitem--info {
      padding-bottom: 60px; } }

.TweedehandsPage .pagination {
  padding-top: 200px;
  display: flex;
  justify-content: center; }
  .TweedehandsPage .pagination .pages {
    display: flex;
    justify-content: center; }
    .TweedehandsPage .pagination .pages.reversed {
      flex-direction: row-reverse; }
    .TweedehandsPage .pagination .pages .page {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px; }
      .TweedehandsPage .pagination .pages .page.active h3 {
        color: var(--primary-color); }

.PartnersOverviewPage .partners {
  display: grid;
  grid-template-columns: repeat(3, 560px);
  grid-template-areas: 'first second third';
  grid-gap: 20px;
  margin-left: auto;
  margin-right: auto; }
  .PartnersOverviewPage .partners-container {
    display: flex;
    flex-direction: column; }
  .PartnersOverviewPage .partners.even {
    grid-template-columns: repeat(2, 350px);
    grid-template-areas: 'first second'; }
    .PartnersOverviewPage .partners.even .partner {
      width: 350px;
      height: 350px; }
    @media screen and (max-width: 51.875em) {
      .PartnersOverviewPage .partners.even {
        grid-template-columns: repeat(2, 300px); }
        .PartnersOverviewPage .partners.even .partner {
          width: 300px;
          height: 300px; } }
    @media screen and (max-width: 43.75em) {
      .PartnersOverviewPage .partners.even {
        grid-template-columns: repeat(2, 250px); }
        .PartnersOverviewPage .partners.even .partner {
          width: 250px;
          height: 250px; } }
  @media screen and (min-width: 56.25em) {
    .PartnersOverviewPage .partners.even {
      display: none; } }
  @media screen and (min-width: 37.5em) and (max-width: 56.25em) {
    .PartnersOverviewPage .partners.odd {
      display: none; }
    .PartnersOverviewPage .partners.even {
      display: grid; } }
  @media screen and (max-width: 37.5em) {
    .PartnersOverviewPage .partners.odd {
      display: grid; }
    .PartnersOverviewPage .partners.even {
      display: none; } }
  .PartnersOverviewPage .partners.tile-amount--1 .partner:nth-child(1) {
    grid-area: second; }
  .PartnersOverviewPage .partners.tile-amount--2 .partner:nth-child(1), .PartnersOverviewPage .partners.tile-amount--3 .partner:nth-child(1) {
    grid-area: first; }
  .PartnersOverviewPage .partners.tile-amount--2 .partner:nth-child(2), .PartnersOverviewPage .partners.tile-amount--3 .partner:nth-child(2) {
    grid-area: second; }
  .PartnersOverviewPage .partners.tile-amount--2 .partner:nth-child(3), .PartnersOverviewPage .partners.tile-amount--3 .partner:nth-child(3) {
    grid-area: third; }
  .PartnersOverviewPage .partners.tile-amount--3 .partner:nth-child(1) {
    grid-area: first; }
  .PartnersOverviewPage .partners.tile-amount--3 .partner:nth-child(2) {
    grid-area: second; }
  .PartnersOverviewPage .partners.tile-amount--3 .partner:nth-child(3) {
    grid-area: third; }
  .PartnersOverviewPage .partners .partner {
    width: 560px;
    height: 560px;
    padding: 40px;
    position: relative;
    transition: background-color .4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
    margin: 10px; }
    .PartnersOverviewPage .partners .partner.has-link .arrow {
      opacity: 0;
      transition: opacity .4s ease;
      position: absolute;
      right: 40px;
      top: 40px; }
    .PartnersOverviewPage .partners .partner.has-link:hover {
      background-color: var(--primary-color); }
      .PartnersOverviewPage .partners .partner.has-link:hover .arrow {
        opacity: 1; }
  @media screen and (max-width: 115.625em) {
    .PartnersOverviewPage .partners {
      grid-template-columns: repeat(3, 400px); }
      .PartnersOverviewPage .partners .partner {
        width: 400px;
        height: 400px; } }
  @media screen and (max-width: 85em) {
    .PartnersOverviewPage .partners {
      grid-template-columns: repeat(3, 300px); }
      .PartnersOverviewPage .partners .partner {
        width: 300px;
        height: 300px; }
        .PartnersOverviewPage .partners .partner.has-link .arrow {
          right: 20px;
          top: 20px;
          transform: scale(0.6);
          transform-origin: top right; } }
  @media screen and (max-width: 66.25em) {
    .PartnersOverviewPage .partners {
      grid-template-columns: repeat(3, 250px); }
      .PartnersOverviewPage .partners .partner {
        width: 250px;
        height: 250px;
        padding: 20px; } }
  @media screen and (max-width: 56.25em) {
    .PartnersOverviewPage .partners {
      grid-template-columns: 400px;
      grid-template-areas: 'first' 'second' 'third'; }
      .PartnersOverviewPage .partners .partner {
        width: 400px;
        height: 400px; } }
  @media screen and (max-width: 37.5em) {
    .PartnersOverviewPage .partners {
      grid-template-columns: 260px;
      margin-bottom: 20px; }
      .PartnersOverviewPage .partners .partner {
        width: 260px;
        height: 260px;
        margin: 0;
        padding: 30px; }
        .PartnersOverviewPage .partners .partner.has-link .arrow {
          right: 20px;
          top: 20px;
          transform: scale(0.7); } }
