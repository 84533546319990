.nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-primary, &-secondary {
        .link {
            &.active {
                color: var(--primary-color);
            }
        }
        
        .link + .link {
            margin-left: 40px;

            @include mq($until: 1600px) {
                margin-left: 20px;
            }
        }
    }

    &-primary {
        margin-top: 5px;

        .link {
            font-size: 18px;
        }
    }

    &-secondary {
        margin-bottom: 5px;
        display: flex;

        * {
            color: $cc-darkGrey;

            &:hover, &:focus, &:active {
                color: $cc-darkGrey;
            }
        }

        .link {
            font-size: 12px;
            font-family: $font-base;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 1px;          
        }

        .link + .link {
            margin-left: 20px;
        }

        .socials {
            margin-left: 20px;
            display: flex;

            .link {
                display: flex;
            }

            .link + .link {
                margin-left: 10px;
            }

            svg {
                path {
                    fill: $cc-darkGrey;
                }
            }
        }

        .language {
            margin-left: 25px;
            display: flex;
            align-items: center;

            > .line {
                margin-right: 20px;
                width: 2px;
                height: 20px;
                background-color: $cc-darkGrey;
            }

            > a {
                &:hover {
                    color: var(--primary-color);
                }
            }

            > a + a {
                margin-left: 10px !important;
            }

            @include mq($until: $resp-nav) {
                margin-left: 0;
                margin-top: 20px;

                > .line {
                    display: none;
                }

                > a + a {
                    margin-top: 0 !important;
                }
            }
        }
    }

    &-primary, &-secondary {
        .link {
            &:hover {
                @include mq($from: $resp-nav) {
                    color: var(--primary-color) !important;
                }
                
                @include mq($until: $resp-nav) {
                    color: $cc-black !important;
                }
            }
        }
    }

    &-hamburger {
        &--icon {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 44px;
            height: 44px;
            cursor: pointer;

            span {
                margin: 2px 0;
                width: 30px;
                height: 3px;
                background-color: $cc-black;
                transition: transform .4s ease, background-color .4s ease;

                &:last-of-type {
                    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
                    transition: transform .4s ease, clip-path .4s ease, background-color .4s ease;
                }
            }

            @include mq($from: $resp-nav) {
                display: none;
            }
        }

        &--checkbox {
            display: none;

            @include mq($until: $resp-nav) {
                & ~ .logo-container, & ~ .nav-hamburger--icon {
                    z-index: 1;
                }

                & ~ .nav {
                    position: fixed;
                    transition: transform .4s ease;
                    transform: translateY(-100vh);
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: var(--primary-color);
                    align-items: center;
                    justify-content: center;
                    flex-direction: column-reverse;

                    .nav {
                        &-secondary {
                            margin-top: 20px;

                            * {
                                color: $cc-grey;
                            }
                        }

                        &-primary {
                            * {
                                color: $cc-white;
                            }
                        }

                        &-secondary, &-primary {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
    
                            .link + .link {
                                margin-left: 0;
                                margin-top: 5px;
                            }

                            .link {
                                &.active {
                                    color: $cc-black;
                                }
                            }
                        }
                    }
                }

                &:checked {
                    & ~ .logo-container, & ~ .nav-hamburger--icon {
                        position: fixed;                        
                    }

                    & ~ .logo-container {
                        svg {
                            path {
                                fill: $cc-white;
                            }
                        }
                    }

                    & ~ .nav {
                        transform: translateY(0);

                        &-hamburger {
                            &--icon {
                                top: 78px;
                                right: 60px;

                                span {
                                    background-color: $cc-white;

                                    &:first-of-type {
                                        transform: translateY(3px) rotate(45deg);
                                    }
    
                                    &:last-of-type {
                                        transform: translateY(-4px) rotate(-45deg);
                                        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                & ~ .nav {
                    &-hamburger {
                        &--icon {
                            position: absolute;
                            top: 28px;
                            right: 20px;
                        }
                    }
                }

                &:checked {
                    & ~ .nav {
                        &-hamburger {
                            &--icon {
                                top: 28px;
                                right: 20px;
                            }
                        }                    
                    }
                }
            }
        }
    }
}