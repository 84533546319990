/* Nav */
.Nav {

    &-list {
        margin: 0;
        padding: 0;
        list-style: none;
        list-style-type: none;

        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex: 0 1 auto;

        @include mq($until: $viewport--lg, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        } 
    }

    &-item {
        margin: 0;
        list-style: none;

        &.has-dropdown {
            position: relative;

            @include mq($from: 1691px) {

                &:hover .Nav-dropdown {
                    opacity: 1;
                    visibility: visible;

                    &::before {
                        height: 30px;
                    }
                }
            }
        }
    }

    &-link {
        position: relative;
        user-select: none;
        display: block;
        font-size: 13px;
        font-family: $font-sans;
        font-weight: 400;
        color: $cc-black;
        text-decoration: none;
        margin-left: 30px;
        padding-bottom: 5px;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     height: 1px;
        //     left: calc(50% - 20px);
        //     width: 0;
        //     bottom: 0;
        //     z-index: 1;
        //     margin: 0 auto;
        //     background-color: $cc-black;
        //     transition-property: width;
        //     transition-duration: 0.3s;
        //     transition-timing-function: ease-out;
        // }

        &:hover, &:focus, &:active {
            color: $cc-black;

            // &::after {
            //     content: "";
            //     width: 40px;
            // }
        }
        
        &.is-active {
            color: $cc-black;
            
            // &::after {
            //     content: "";
            //     width: 40px;
            // }
        }
    }

    &-dropdown {
        position: absolute;
        opacity: 0;
        list-style: none;
        visibility: hidden;
        transition: opacity ease .3s, visibility ease .3s;
        top: u(30);
        text-align: left;
        margin: 0 auto;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        white-space: nowrap;
        padding: 20px;
        background-color: $white;
        width: 160px;

        &.showDropdown {
            opacity: 1;
            visibility: visible;
    
            &::before {
                height: 30px;
            }
        }
        
        &-container {
            .Nav-item {
                a {
                    display:inline-block;
                    // margin-bottom: 5px;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: $font-sans;
                    border-bottom: 2px solid rgba(255,255,255, 0);
                    transition: border-color .2s ease;
                    line-height: 1;
                    padding-bottom: 5px;

                    // &::before {
                    //     display: block;
                    //     content: attr(title);
                    //     font-weight: 700;
                    //     height: 0;
                    //     overflow: hidden;
                    //     visibility: hidden;
                    //     line-height: 1.4;
                    // }
                }

                a + a {
                    padding-top: 10px;
                }
            }
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     width: 1px;
        //     height: 0;
        //     background-color: $cc-black;
        //     top: -40px;
        //     transition: height .3s ease;
        // }
    }
}