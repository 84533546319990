.cta-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 200px;
    padding-bottom: 200px;

    h1 {
        color: $cc-darkerGrey;
    }

    .ctas {
        margin-top: 50px;
    }

    @include mq($until: $viewport--md) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
   
    @include mq($until: $viewport--sm) {
        min-height: unset;
    }
}