.PartnersOverviewPage {
    .partners {
        display: grid;
        grid-template-columns: repeat(3, 560px);
        grid-template-areas: 'first second third';
        grid-gap: 20px;
        margin-left: auto;
        margin-right: auto;

        &-container {
            display: flex;
            flex-direction: column;
        }

        &.even {
            grid-template-columns: repeat(2, 350px);
            grid-template-areas: 'first second';

            .partner {
                width: 350px;
                height: 350px;
            }

            @include mq($until: 830px) {
                grid-template-columns: repeat(2, 300px);
                
                .partner {
                    width: 300px;
                    height: 300px;
                }
            }

            @include mq($until: 700px) {
                grid-template-columns: repeat(2, 250px);
                
                .partner {
                    width: 250px;
                    height: 250px;
                }
            }
        }

        @include mq($from: 900px) {
            &.even {
                display: none;
            }
        }
        
        @include mq($from: $viewport--sm, $until: 900px) {
            &.odd {
                display: none;
            }

            &.even {
                display: grid;
            }
        }

        @include mq($until: $viewport--sm) {
            &.odd {
                display: grid;
            }
            
            &.even {
                display: none;
            }
        }

        &.tile-amount {
            &--1 {
                .partner {
                    &:nth-child(1) {
                        grid-area: second;
                    }
                }
            }

            &--2, &--3 {
                .partner {
                    &:nth-child(1) {
                        grid-area: first;
                    }
                    
                    &:nth-child(2) {
                        grid-area: second;
                    }
                    
                    &:nth-child(3) {
                        grid-area: third;
                    }
                }
            }

            &--3 {
                .partner {
                    &:nth-child(1) {
                        grid-area: first;
                    }
                    
                    &:nth-child(2) {
                        grid-area: second;
                    }
                    
                    &:nth-child(3) {
                        grid-area: third;
                    }
                }
            }
        }

        .partner {
            width: 560px;
            height: 560px;
            padding: 40px;
            position: relative;
            transition: background-color .4s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $cc-grey;
            margin: 10px;

            &.has-link {
                .arrow {
                    opacity: 0;
                    transition: opacity .4s ease;
                    position: absolute;
                    right: 40px;
                    top: 40px;
                }

                &:hover {
                    background-color: var(--primary-color);

                    .arrow {
                        opacity: 1;
                    }
                }
            }
        }

        @include mq($until: 1850px) {
            grid-template-columns: repeat(3, 400px);

            .partner {
                width: 400px;
                height: 400px;
            }
        }
        
        @include mq($until: 1360px) {
            grid-template-columns: repeat(3, 300px);

            .partner {
                width: 300px;
                height: 300px;

                &.has-link {
                    .arrow {
                        right: 20px;
                        top: 20px;
                        transform: scale(.6);
                        transform-origin: top right;
                    }
                }
            }
        }
       
        @include mq($until: 1060px) {
            grid-template-columns: repeat(3, 250px);

            .partner {
                width: 250px;
                height: 250px;
                padding: 20px;
            }
        }

        @include mq($until: 900px) {
            grid-template-columns: 400px;
            grid-template-areas: 'first'
            'second'
            'third';

            .partner {
                width: 400px;
                height: 400px;
            }
        }

        @include mq($until: $viewport--sm) {
            grid-template-columns: 260px;
            margin-bottom: 20px;

            .partner {
                width: 260px;
                height: 260px;
                margin: 0;
                padding: 30px;

                &.has-link {
                    .arrow {
                        right: 20px;
                        top: 20px;
                        transform: scale(.7);
                    }
                }
            }
        }
    }
}