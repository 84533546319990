.container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;

    &--big {
        max-width: 1600px;
    }
    
    &--medium {
        max-width: 1060px;
    }

    &--small {
        max-width: 860px;
    }
    
    &--extrasmall {
        max-width: 560px;
    }

    @include mq($until: 1600px) {
        &:not(.without-padding) {
            padding-left: var(--spacing-nav);
            padding-right: var(--spacing-nav);
        }
    }   
}