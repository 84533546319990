/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-base:                      "Shapiro 45", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900
$font-bold:                      "Shapiro 55", sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900

@font-face {
    font-family: 'Shapiro 45';
    src: url('../../fonts/shapiro/Shapiro-45WelterExtd.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-45WelterExtd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shapiro 45';
    src: url('../../fonts/shapiro/Shapiro-45WelterWide.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-45WelterWide.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shapiro 45';
    src: url('../../fonts/shapiro/Shapiro-45Welter.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-45Welter.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shapiro 45';
    src: url('../../fonts/shapiro/Shapiro-45WelterText.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-45WelterText.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shapiro 55';
    src: url('../../fonts/shapiro/Shapiro-55Middle.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-55Middle.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Shapiro 55';
    src: url('../../fonts/shapiro/Shapiro-55MiddleText.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-55MiddleText.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shapiro 55';
    src: url('../../fonts/shapiro/Shapiro-55MiddleWide.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-55MiddleWide.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Shapiro 55';
    src: url('../../fonts/shapiro/Shapiro-55MiddleExtd.woff2') format('woff2'),
        url('../../fonts/shapiro/Shapiro-55MiddleExtd.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


