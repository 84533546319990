form {
    .ff-form-errors, .ff-errors, .Form-errors, .freeform-form-has-errors {
        color: $cc-red;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: 600;

        @include mq($until: $viewport--sm) {
            font-size: 16px !important;
            line-height: 24px;

            * {
                font-size: inherit;
                line-height: inherit;
            }
        }
    }

    .ff-errors, .Form-errors, .freeform-form-has-errors {
        margin-top: 10px;
        padding-left: 0;

        ul {
            padding-left: 0;
        }

        li {
            margin-bottom: 0;
            list-style: none;
        }
    }

    .ff-form-success {
        color: $cc-green;
        font-weight: bold;
        max-width: 320px;

        & ~ * {
            display: none;
        }
    }

    .active-field {
        label {
            opacity: 1 !important;
        }

        input {
            padding-top: 25px !important;
            padding-bottom: 7px !important;
            border-color: $cc-black !important;
        }
    }

    .download-submit {        
        &--btn {
            opacity: 0; 
            pointer-events: none;
            position: absolute;       
        }
    }

    .freeform-column {
        position: relative;
    }

    .input-fields {        
        margin-bottom: 40px;

        .file-label {
            .cta {
                transition: background-color .4s ease, color .4s ease;
            }

            .remove-file {
                opacity: 0;
                transition: opacity .4s ease;
                pointer-events: none;
                margin-right: 10px;
                display: none;
                align-items: center;
            }

            &.upload-ready {
                .cta {
                    background-color: var(--primary-color);
                    color: $cc-white;
                }

                .remove-file {
                    opacity: 1;
                    pointer-events: all;
                    display: flex;
                }
            }
        }

        &.html {
            .line {
                background-color: var(--primary-color);
                width: 100%;
                height: 1px;
            }
        }
        
        .Form {
            &-controls {
                position: relative;
                display: flex;
                align-items: flex-start;
                flex-direction: column;

                &.Form-item {
                    &--action {
                        align-items: flex-end;

                        button {
                            font-size: 22px;
                            line-height: 30px;

                            @include mq($until: $viewport--md) {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }

            &-label {                
                color: $cc-darkGrey;
                margin-bottom: 10px;
            }

            &-instructions {
                display: none;
            }
        }

        p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 20px;

            strong {
                font-size: 24px;
                line-height: 32px;
                font-weight: 600;
            }
        }

        &.submit {
            margin-top: 40px;
        }

        &.html {
            margin-top: 30px;
        }

        &.accept {
            display: flex;

            label {
                cursor: pointer;
                font-size: 16px;
                color: $cc-darkGrey;
                line-height: 1;
                padding-left: 10px;
                
                a {
                    font-size: inherit;
                    color: inherit;
                    text-decoration: underline;
                }
            }

            p {
                font-size: 18px;
                line-height: 28px;
            }

            a {
                color: $cc-black;
            }
            
            input {
                display: none;
                
                &:checked {
                    & ~ .fake-checkbox {
                        background-color: var(--primary-color);
                    }
                }
            }
            
            .fake-checkbox {
                border: 2px solid $cc-darkGrey;
                display: flex;
                width: 20px;
                height: 20px;
                transition: background-color .4s ease;
                cursor: pointer;                
            }

            @include mq($until: $viewport--sm) {
                grid-template-columns: 25px 1fr !important;
                grid-template-areas:
                    'checkbox privacy'
                    'submit submit';

                > * {
                    &:nth-child(1) {
                        grid-area: checkbox;
                    }
                    
                    &:nth-child(2) {
                        grid-area: privacy;
                    }
                    
                    &:nth-child(3) {
                        grid-area: submit;
                        align-items: flex-start;
                        margin-top: 10px;
                    }
                }
            }
        }

        &.select {
            position: relative;

            &:after {
                content: '';                
                position: absolute;
                right: 20px;
                top: 10px;
                width: 10px;
                height: 10px;
            }
       
            select {
                cursor: pointer;
                position: relative;           
            }
        }

        select {
            background: var(--primary-color) url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCA1MSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzY2XzQ0NykiPgo8cGF0aCBkPSJNNTAuNTUzMiAwTDI5LjQ4MyAyMS4yNTk1TDI2Ljc2NDcgMjRIMjMuNzkwNEwyMS4wNzIxIDIxLjI1NzVMMCAwSDguNDEwODlMMjUuMjc2NiAxNy4wMTU3TDQyLjE0MjMgMEg1MC41NTMyWiIgZmlsbD0iI0ZGNTAwMCIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzY2XzQ0NyI+CjxyZWN0IHdpZHRoPSI1MC41NTMyIiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=") no-repeat;
            background-position: right 40px top 50%;
        }
        
        input, textarea {
            &[type=file] {
                display: none;
            }

            &:not([type=radio]), &:not(.readonly) {                            
                transition: background-color .4s ease, color .4s ease;
                
                &:focus {
                    background-color: var(--primary-color);
                    color: $cc-white;
                }
            }
        }
        
        textarea {
            resize: none;
            height: 270px !important;

            @include mq($until: $viewport--md) {
                height: 200px !important;
            }
        }

        select, input, textarea {
            appearance:none;
            outline: none;
            color: $cc-black;
            font-size: 22px;
            line-height: 30px;  
            border-radius: 0;  
            border: 1px solid $cc-grey;
            background-color: $cc-grey;  
            padding: 20px 40px;   
            height: 90px;
            display: flex;
            align-items: center;
            width: 100%;  

            &::placeholder {
                color: $cc-black;
            }

            @include mq($until: $viewport--md) {
                font-size: 18px;
                padding: 15px 25px;
                height: 70px;
            }
        }

        * {
            &:not(.cta) {
                font-family: $font-base;
            }
        }

        &.field-amount {
            &--1 {
                width: 100%;
            }

            &--2 {
                &:not(.accept) {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    
                    > * {
                        &:not(:last-child) {
                            margin-right: 20px;
                        }
                    }
                }
            }

            &--3 {
                display: grid;
                grid-template-columns: 50% repeat(2, 25%);

                &.accept {
                    grid-template-columns: 20px repeat(2, auto);
                }
                
                > * {
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            margin-bottom: 20px;
        }
    }

    @include mq($until: $viewport--sm) {
        .field-amount--3 {
            &:not(.accept) {
                display: grid;
                grid-template-columns: repeat(2, 1fr) !important;
                grid-template-areas: 'street street''number addition';
    
                > * {
                    &:nth-child(1) {
                        grid-area: street;
                        margin-right: 0 !important;
                        margin-bottom: 20px;
                    }
                    
                    &:nth-child(2) {
                        grid-area: number;
                    }
                    
                    &:nth-child(3) {
                        grid-area: addition;
                    }
                }
            }
        }
    }
    
    @include mq($until: 400px) {
        .field-amount {
            &--2 {
                &:not(.email) {
                    grid-template-columns: 1fr !important;
                }

                > * {
                    &:nth-child(1) {
                        margin-right: 0 !important;
                        margin-bottom: 20px;
                    }
                }
            }
            
            &--3 {
                display: grid;
                grid-template-columns: 1fr !important;
                grid-template-areas: 'street''number''addition';
    
                > * {
                    &:nth-child(2) {
                        margin-right: 0 !important;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    button {
        cursor: pointer;
    }
}