.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-nav);

    .nav {
        &-primary {
            * {
                color: $cc-black;
            }
        }
    }

    &.light {
        .nav {
            &-primary {
                * {
                    color: $cc-white;
                }
            }

            &-hamburger {
                &--icon {
                    span {
                        background-color: $cc-white;
                    }
                }
            }
        }
    }  
    
    a {
        font-family: $font-bold;
    }

    .logo-normal {
        max-width: 450px;

        svg {
            max-width: inherit;

            path {
                transition: fill .4s ease;
            }
        }      
        
        @include mq($until: 1500px) {
            max-width: 400px;
        }
        
        @include mq($until: $viewport--sm) {
            max-width: 170px;
        }
    }

    .title-to-logo {
        width: 540px;
        height: 80px;
        overflow: hidden;
        position: relative;

        .content {            
            position: absolute;
            top: 0;
            left: 0;
            transition: transform .4s ease;

            .title, .logo {
                width: 540px;
                height: 80px;
            }

            .title {
                h1 {
                    color: $cc-white;
                }
            }

            .logo {
                svg {
                    width: 100%;
                }
            }
        }

        &.active {
            .content {
                transform: translateY(-80px);
            }
        }

        @include mq($until: 1500px) {
            width: 400px;

            .content {
                .title, .logo {
                    width: 400px;
                }

                .title {
                    h1 {
                        font-size: 45px;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            width: 170px;

            .content {
                .title, .logo {
                    width: 170px;
                }

                .logo {
                    svg {
                        height: 100%;
                    }
                }

                .title {
                    display: flex;
                    align-items: center;
                    transform: translateY(-2px);

                    h1 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}