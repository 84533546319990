.OverOnsPage {
    .textblocks {
        margin-top: 200px;

        @include mq($until: $viewport--md) {         
            margin-top: 100px;         
        }
        
        @include mq($until: $viewport--sm) {         
            margin-top: 80px;         
        }
    }

    .timeline {
        grid-area: moments;
        width: 840px;
        
        .moment {
            max-width: 660px;
            display: flex;
            align-items: center;
       
            .year {
                max-width: 280px;
                min-width: 280px;                
            }
        }

        .moment + .moment {
            margin-top: 60px;
        }

        &-section {
            display: flex;
            justify-content: center;
            position: relative;
            margin-top: 220px;
            
            .left {               
                padding-top: 200px;
                padding-right: 100px;
                
                .text {                   
                    margin-top: 50px;
                    max-width: 420px;

                    &-container {
                        min-width: 720px;
                        
                        h2 {
                            color: var(--primary-color);    
                        }
                        
                        position: sticky;
                        top: 200px;

                        @include mq($until: 1950px) {
                            min-width: 520px;
                        }
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;

                .image {
                    margin-right: 200px;
                    width: 840px;
                    height: 680px;
        
                    img {
                        max-height: inherit;
                        min-height: inherit;
                        max-width: inherit;
                        min-width: inherit;
                    }
        
                    @include mq($until: 1950px) {
                        margin-right: 0;
                    }
                }
            }

            @include mq($until: 1400px) {
                .left {
                    padding-right: 40px;
                }
            }
        }
        
        &-container {            
            margin-top: 80px;
            justify-content: space-between;
        }

        @include mq($until: 1500px) {
            width: 640px;

            &-section {
                .left {
                    padding-top: 120px;

                    .text-container {
                        top: 120px;
                    }
                }

                .right {
                    .image {
                        width: 680px;
                        height: 520px;
                    }
                }
            }
        }
        
        @include mq($until: 1280px) {
            width: 500px;

            &-section {
                .left {
                    padding-top: 80px;

                    .text-container {
                        top: 80px;
                        min-width: 470px;
                    }
                }

                .right {
                    .image {
                        width: 520px;
                        height: 560px;
                    }
                }
            }

            .moment {
                max-width: 570px;

                .year {
                    max-width: 180px;
                    min-width: 180px;
                }
            }
        }
        
        @include mq($until: 1060px) {
            width: 500px;
            
            &-section {
                .left {
                    .text-container {
                        min-width: 340px;
                        max-width: 340px;
                        margin-right: 60px;
                    }
                }
            }

            .moment {
                max-width: 570px;

                .year {
                    max-width: 180px;
                    min-width: 180px;
                }
            }
        }

        @include mq($until: 1000px) {
            width: 100%;

            .moment {
                max-width: unset;
            }

            &-section {
                flex-direction: column;

                .left {
                    position: relative;
                    padding-top: 0;
                    padding-right: 0;
                    
                    .text-container {
                        top: 0;
                        min-width: unset;
                        max-width: unset;
                    }
                }

                .right {
                    margin-top: 50px;

                    .image {
                        width: 100%;
                        max-width: 850px;
                    }
                }
            }
        }

        @include mq($until: $viewport--md) {
            &-section {
                margin-top: 100px;
            }
        }
        
        @include mq($until: $viewport--sm) {     
            .moment {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .year {
                    margin-bottom: 10px;
                    min-width: unset;
                    max-width: unset;
                }
            }  
            
            .moment + .moment {
                margin-top: 0px;
            }

            &-container {
                margin-top: 60px;
            }

            &-section {
                margin-top: 80px;
                
                .left {
                    .text {
                        margin-top: 20px;

                        &-container {

                        }
                    }
                }
                
                .right {
                    margin-top: 30px;
                }
            }
        }
    }
}