.tweedehandsitems {
    display: flex;
 
    &-section {
        display: flex;
        margin-left: var(--spacing-nav);
        
        .left {
            min-width: 400px;
            margin-right: 120px;

            .cta {
                margin-top: 40px;
                width: max-content;
            }
        }
    }
    
    .tweedehandsitem {
        width: 100%;
        background-color: $cc-grey;
        transition: width .4s ease, background-color .4s ease;
        color: $cc-black;
        margin: 10px;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &--figure {
            height: 550px;
            overflow: hidden;

            .image {
                height: 100%;
            }

            @include mq($until: 1450px) {
                height: 400px;
            }
        }

        &--info {
            padding: 60px;
            padding-right: 100px;
            padding-bottom: 100px;

    
            .title {
                margin-top: 30px;
                margin-bottom: 0;
                hyphens: auto;
            }

            .text {
                margin-top: 30px;
            }

            > * {
                max-width: 400px;
                transition: color .4s ease;
            }

            &-container {

                .contact {
                    text-align: center;
                    opacity: 0;
                    transition: opacity .4s ease;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    bottom: 30px;
                    color: $cc-black;
                    text-decoration: underline;
                }
            }
        }

        &:hover, &:focus, &:active {
            background-color: var(--primary-color);
            width: 120%;  
            color: $cc-white;        

            .tweedehandsitem {                                
                &--info {
                    .arrow {
                        opacity: 1;
                    }
    
                    > * {
                        color: $cc-white;
                    }

                    &-container {
                        .contact {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}