.TweedehandsPage {
    .tweedehandsitems {
        padding: 0 var(--spacing-nav);
        display: flex;
        justify-content: center;
    
        &-container {
            display: flex;
            flex-direction: column;
            max-width: 1860px;

            @include mq($from: 1350px) {
                &.even {
                    display: none;
                }
            }
            
            @include mq($from: 900px, $until: 1350px) {
                &.odd {
                    display: none;
                }

                &.even {
                    display: flex;
                }
            }

            @include mq($until: 900px) {
                &.odd {
                    display: flex;
                    
                    .tweedehandsitems {
                        flex-direction: column;
                        align-items: center;
                    }
                }
                
                &.even {
                    display: none;
                }
            }
        }
    
        .tweedehandsitem {
            width: 560px;
            margin: 10px !important;
    
            &:hover {
                width: 660px;
            }
        }

        @include mq($until: 900px) {
            align-items: center;
            margin-top: 0;

            .tweedehandsitem {
                max-width: 560px;
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;

                &--info {
                    padding: 30px;
                }
                
                .text {
                    margin-top: 20px;
                    padding-right: 40px;
                }

                &:hover {
                    width: 100%;
                }
            }
        }

        @include mq($until: $viewport--sm) {
            .tweedehandsitem {
                &--figure {
                    height: 320px;
                }

                &--info {
                    transition: padding-bottom .4s ease;

                    .title {
                        margin-top: 0;
                    }
                }

                &:hover {
                    .tweedehandsitem--info {
                        padding-bottom: 60px;
                    }
                }
            }
        }
    }

    .pagination {
        padding-top: 200px;
        display: flex;
        justify-content: center;

        .pages {
            display: flex;
            justify-content: center;

            &.reversed {
                flex-direction: row-reverse;
            }

            .page {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 5px;

                &.active {
                    h3 {
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}