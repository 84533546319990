.cta-list {
    display: flex;
    flex-direction: column;

    &--item {
        background-color: $cc-grey;
        border-top: 1px solid var(--primary-color);
        cursor: pointer;
        transition: background-color .4s ease;

        .container {
            padding: 65px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }

        &:last-of-type {
            border-bottom: 1px solid var(--primary-color);
        }

        .arrow {
            transform: rotate(45deg);
            opacity: 0;
            transition: opacity .4s ease;
        }

        h2 {
            color: var(--primary-color);
            transition: color .4s ease;
        }

        &:hover {
            opacity: 1;
            background-color: var(--primary-color);

            h2 {
                color: $cc-white;
            }

            .arrow {
                opacity: 1;
            }
        }

        @include mq($until: 1600px) {
            .container {
                padding-left: var(--spacing-nav);
                padding-right: var(--spacing-nav);
            }
        }

        @include mq($until: 1200px) {
            .container {
                padding: 35px var(--spacing-nav);

                .arrow {
                    transform: scale(.7) rotate(45deg);
                }

                h2 {
                    hyphens: auto;
                    word-break: break-word;
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            .container {
                padding: 15px var(--spacing-nav);

                .arrow {
                    transform: scale(.5) rotate(45deg);
                }

                h2 {
                    hyphens: auto;
                }
            }
        }
    }
}