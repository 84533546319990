.page-header {   
    &:not(.with-image) {
        .title {
            margin-top: 120px;
            margin-bottom: 180px;

            @include mq($until: $viewport--md) {
                margin-top: 60px;
                margin-bottom: 100px;
            }
           
            @include mq($until: $viewport--sm) {
                margin-top: 40px;
                margin-bottom: 60px;
                word-break: break-word;
            }
        }

        .image {
            &-container {
                position: relative;
                overflow: hidden;
                height: 840px;

                @include mq($until: 1100px) {
                    height: 600px;
                }
                
                @include mq($until: $viewport--md) {
                    height: 400px;
                }
                
                @include mq($until: $viewport--sm) {
                    height: 300px;
                }
            }

            width: 100%;
            height: 120%;
            position: absolute;
            z-index: -1;
            top: -20%;
        }
    } 

    &.with-image {
        margin-top: calc(#{var(--structure-header-height)} * -1);
        border-bottom: 5px solid var(--primary-color);
        position: relative;
    
        .image {
            height: calc(#{100vh} - 5px);
            background-attachment: fixed;

            @include mq($until: $viewport--sm) {
                background-attachment: unset;
            }
        }
    
        .logo {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: var(--spacing-nav);
    
            svg {
                width: 100%;
                height: 100%;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 300px;
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
        }

        @include mq($until: $viewport--md) {
            &.with-image {
                .image {
                    height: 660px;
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            &.with-image {
                .image {
                    height: 500px;
                }
            }
        }
    }
}