.products-list {
    .hidden {
        display: none;
    }
    
    .product {       
        $self: &;

        input[type=radio] {
            display: none;

            &:checked {
                & ~ label {
                    .arrow {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        
        label {
            display: flex;
            flex-direction: column;
            background-color: $cc-grey;
            transition: background-color .4s ease;
            border-top: 1px solid var(--primary-color);
            cursor: pointer;
            
            .label-content {
                padding: 65px var(--spacing-nav);
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                @include mq($until: $viewport--md) {
                    padding: 35px var(--spacing-nav);
                }
            }

            .product-info {
                height: 0px;
                overflow: hidden;
                transition: height .4s ease;
                background-color: var(--primary-color);
                display: grid;
                grid-template-columns: auto 50vw;
                
                .left {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    height: fit-content;
                    padding-top: 160px;
                    padding-bottom: 160px;
                    padding-right: 80px;

                    p {
                        color: $cc-white;
                    }

                    > div {
                        max-width: 420px;
                        margin-bottom: 50px;
                    }

                    .cta {
                        background-color: $cc-darkerGrey;

                        &:hover {
                            background-color: $cc-lightGrey;
                            color: $cc-darkerGrey;
                        }
                    }

                    @include mq($until: $viewport--md) {                        
                        padding: 100px var(--spacing-nav) !important;

                        > div {
                            margin-bottom: 20px;
                        }
                    }
                    
                    @include mq($until: $viewport--sm) {                        
                        padding: 60px var(--spacing-nav) !important;
                    }
                }
                
                .image {
                    height: inherit;
                    width: 100%;
                }

                @include mq($until: $viewport--sm) {
                    grid-template-columns: auto;

                    .image {
                        max-height: 300px;
                    }
                }
            }

            h2 {
                color: var(--primary-color);
                transition: color .4s ease;
            }

            .arrow {
                transition: transform .4s ease;

                svg {
                    path {
                        fill: var(--primary-color);
                        transition: fill .4s ease;
                    }
                }

                @include mq($until: $viewport--sm) {
                    width: 40px;
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &:last-of-type {
            label {
                border-bottom: 1px solid var(--primary-color);
            }
        }
        
        &:hover, input[type=radio]:checked {
            label, & ~ label {
                background-color: var(--primary-color);

                h2 {
                    color: $cc-white;
                }

                .arrow {
                    svg {
                        path {
                            fill: $cc-white;
                        }
                    }
                }
            }
        }

        &.electric {
            .label {
                &-content {
                    h2 {
                        color: $cc-electric;
                        position: relative;

                        .electric-icon {
                            position: absolute;
                            left: -40px;
                            height: 30px;
                            display: flex;
                            align-items: center;
                            top: calc(50% - 15px);

                            svg {
                                path {
                                    fill: $cc-electric;
                                    transition: fill .4s ease;
                                }
                            }
                        }
                    }

                    .arrow {
                        svg {
                            path {
                                fill: $cc-electric;
                            }
                        }
                    }
                }
            } 

            .product {
                &-info {
                    background-color: $cc-electric;

                    .left {
                        p {
                            color: $cc-white;
                        }

                        .cta {
                            &.orange {
                                background-color: $cc-white;
                                color: $cc-electric;

                                &:hover {
                                    background-color: $cc-lightGrey;
                                    color: $cc-electric;
                                }
                            }
                        }
                    }
                }
            }
            
            &:hover, input[type=radio]:checked {
                label, & ~ label {
                    background-color: $cc-electric;
    
                    h2 {
                        color: $cc-white;

                        .electric-icon {
                            svg {
                                path {
                                    fill: $cc-white;
                                }
                            }
                        }
                    }
    
                    .arrow {
                        svg {
                            path {
                                fill: $cc-white;
                            }
                        }
                    }
                }
            }

            @include mq($until: $viewport--md) {
                .label {
                    &-content {
                        h2 {
                            display: flex;

                            .electric-icon {
                                position: relative;
                                left: 0;
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}