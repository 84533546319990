.JobsOverviewPage {
    .title {
        margin-top: 120px;
        margin-bottom: 180px;

        @include mq($until: $viewport--md) {
            margin-top: 60px;
            margin-bottom: 100px;
        }
       
        @include mq($until: $viewport--sm) {
            margin-top: 40px;
            margin-bottom: 60px;
        }
    }

    .textblocks {
        &.container {
            margin-top: 140px;

            .textblock {
                padding: 0;
            }
        }
    }
}

.JobsPage {
    .title {
        margin-top: 120px;
        margin-bottom: 180px;

        &-container {
            background-color: var(--primary-color);

            h2 {
                color: $cc-white;
                padding: 60px 0;
            }
        }

        @include mq($until: $viewport--md) {
            margin-top: 60px;
            margin-bottom: 100px;
        }
       
        @include mq($until: $viewport--sm) {
            margin-top: 40px;
            margin-bottom: 60px;

            &-container {
                h2 {
                    padding: 30px 0;
                }
            }
        }
    }

    .jobinfo {        
        &-item {
            width: 100%;
            display: flex;
            padding: 60px 0;

            .left {
                display: flex;
                flex-direction: column;
                width: 50%;

                h3 {
                    max-width: 340px;
                }

                > * {
                    max-width: 420px;
                }

                .text {
                    margin-top: 30px;
                }

                .ctas {
                    display: flex;
                    flex-direction: column;
                    margin-top: 50px;

                    .cta {
                        width: max-content;
                    }

                    .cta + .cta {
                        margin-left: 0;                       
                    }
                }
            }

            .right {
                width: 50%;

                > * {
                    width: 70%;

                    &.Form {
                        width: 100%;
                    }
                }

                ul {
                    margin-bottom: 0;

                    li + li {
                        margin-top: 40px;
                    }
                }
            }

            &.has-line {
                border-top: 1px solid var(--primary-color);
            }

            @include mq($until: 1350px) {
                .left {
                    width: 40%;
                    padding-right: 60px;
                }
                
                .right {
                    width: 60%;
                }
            }

            @include mq($until: 1140px) {                
                .right {
                    .file-label {
                        .cta {
                            padding: 25px 45px 25px 30px;
                        }
                    }
                }
            
            }
           
            @include mq($until: 930px) {
                flex-direction: column;

                .left {
                    width: 100%;
                    padding-right: 0;

                    .text, .ctas {
                        margin-top: 0;
                    }
                }

                .right {
                    width: 100%;

                    > * {
                        width: 100%;
                    }
                    
                    .Form {
                        margin-top: 60px;
                        
                        .file {
                            display: flex;
                            flex-direction: column;
    
                            &-label {
                                .cta {
                                    padding: 25px 45px 25px 30px;
                                }
                            }

                            .Form-controls {
                                &:nth-of-type(2) {
                                    margin-top: 20px;
                                }
                            }
                        }
                    }
                }            
            }            
        }
        
        &-container {
            margin: 80px 0;

            @include mq($until: $viewport--sm) {
                margin: 0;
            }
        }
    }
}