/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F4F4F4 !default;
$cc-grey: #F2F2F2 !default;
$cc-normalGrey: #E6E6E6 !default;
$cc-darkGrey: #B3B3B3 !default;
$cc-darkerGrey: #4D4D4D !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#4D4D4D !default;
$cc-blackShade: #435572 !default;
$cc-red: #ff1e00 !default;
$cc-green: #49e81d !default;
$cc-primary: #FF3C1E !default;
$cc-electric: #209BD8 !default;

:root {
    --primary-color: #FF3C1E;
}

:root.electric{
    --primary-color: #209BD8;
    background-color: $cc-normalGrey;

    .logo-normal {
        svg {
            path {
                fill: $cc-electric;
            }
        }
    }

    .CookieConsent {
        &.customization {
            pointer-events: none;     
            
            .normal-buttons {
              .cta {
                background-color: #205069;
                color: #797878;
              }
            }
        }
    }
}

:root:not(.electric) {
    background-color: $cc-primary;

    body {
        background-color: $cc-white;
    }
}