.ContactPage {
    .headerblock {
        .cta {
            background-color: var(--primary-color);
            color: $cc-white;

            &:hover {
                background-color: $cc-lightGrey;
                color: $cc-black;
            }
        }

        @include mq($from: $viewport--md, $until: 1000px) {
            .image {
                min-width: 350px !important;
                max-width: 350px !important;
                min-height: 440px !important;
                max-height: 440px !important;
            }
        }

        @include mq($until: $viewport--sm) {
            padding-top: 0 !important;
        }
    }

    .form {
        &-container {
            display: flex;
            margin-top: 180px;

            .left {
                max-width: 560px;
                width: 100%;
                margin-right: 200px;
            }

            .right {
                width: 100%;
            }

            @include mq($until: 1900px) {
                .left {
                    margin-right: 140px;
                }
            }

            @include mq($until: 1280px) {
                .left {
                    max-width: 400px;
                    margin-right: 60px;
                }
            }

            @include mq($until: 900px) {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 100px;

                .left {
                    margin-bottom: 40px;
                }
            }
        }
    }
}