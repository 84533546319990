.image {
    &.skewed-corner {
        clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0);

        @include mq($until: 1300px) {
            clip-path: polygon(100% 0, 100% calc(100% - 140px), calc(100% - 140px) 100%, 0 100%, 0 0);
        }
        
        @include mq($until: 1200px) {
            clip-path: polygon(100% 0, 100% calc(100% - 120px), calc(100% - 120px) 100%, 0 100%, 0 0);
        }

        @include mq($until: 1100px) {
            clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0);
        }

        @include mq($until: 1000px) {
            clip-path: polygon(100% 0, 100% calc(100% - 80px), calc(100% - 80px) 100%, 0 100%, 0 0);
        }
    }

    &.rellax {
        @include mq($until: $viewport--sm) {
            position: relative !important;
            top: 0 !important;
            transform: translate3d(0,0,0) !important;
        }
    }
}