.newsitems {
    display: flex;
    width: 100%;
 
    &-section {
        display: flex;
        margin-left: var(--spacing-nav);
        
        .left {
            min-width: 400px;
            margin-right: 120px;

            .cta {
                margin-top: 40px;
                width: max-content;
            }

            @include mq($until: $viewport--sm) {
                min-width: unset;
            }
        }

        @include mq($from: $desktop-size) {
            margin-left: auto;
            width: 100%;
            max-width: 2100px;
        }

        @include mq($from: 1200px) {
            margin-top: 200px;
            padding-left: 40px;
        }
    }
    
    .newsitem {
        width: 100%;
        background-color: $cc-grey;
        cursor: pointer;
        clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
        transition: clip-path .4s ease, width .4s ease, background-color .4s ease;
        color: $cc-black;

        &:nth-child(2n) {
            margin: 0 20px;
        }

        &:last-child {
            margin-right: 0;
        }

        &--figure {
            height: 550px;
            overflow: hidden;

            .image {
                height: 100%;
            }

            @include mq($until: 1450px) {
                height: 400px;
            }
        }

        &--info {
            padding: 60px;
            padding-right: 100px;
            position: relative;

            .arrow {
                position: absolute;
                right: 30px;
                top: 30px;
                opacity: 0;
                width: 40px;
                height: 40px;
                transition: opacity .4s ease;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
    
            .title {
                margin-top: 30px;
                margin-bottom: 0;
                hyphens: auto;
            }

            .text {
                margin-top: 30px;
            }

            > * {
                max-width: 400px;
                transition: color .4s ease;
            }
        }

        &:hover, &:focus, &:active {
            background-color: var(--primary-color);
            clip-path: polygon(100% 0, 100% calc(100% - 160px), calc(100% - 160px) 100%, 0 100%, 0 0); 
            width: 120%;  
            color: $cc-white;        

            .newsitem {                                
                &--info {
                    .arrow {
                        opacity: 1;
                    }
    
                    > * {
                        color: $cc-white;
                    }
                }
            }
        }

        @include mq($until: 1660px) {
            &--info {
                padding-right: 60px;
            }

            &:hover, &:focus, &:active {
                clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0); 
            }
        }

        @include mq($until: 1350px) {
            &--info {
                padding-right: 40px;
                padding: 40px;
            }

            &:hover, &:focus, &:active {
                clip-path: polygon(100% 0, 100% calc(100% - 100px), calc(100% - 100px) 100%, 0 100%, 0 0); 
            }
        }
    }

    @include mq($until: 1460px) {
        &-section {
            .left {
                margin-right: 20px;
            }
        }
    }
    
    @include mq($until: 1300px) {
        margin-top: 40px;
        
        &-section {
            flex-direction: column;
            align-items: flex-start;

            .left {
                margin-right: 0px;
            }
        }
    }
    
    @include mq($until: 900px) {    
        flex-direction: column;

        &-section {
            margin-right: var(--spacing-nav);
        }

        .newsitem {
            &--figure {
                height: 320px;
            }
            
            &:hover {
                width: 100%;
            }
        }

        .newsitem + .newsitem {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    @include mq($until: $viewport--md) {
        &-section {
            margin-top: 80px;
            
            .left {
                .cta {
                    margin-top: 20px;
                }
            }
        }
    }
}