.NieuwsPage {
    .newsitems {
        padding: 0 var(--spacing-nav);
        display: flex;
        justify-content: center;

        &-container {
            display: flex;
            flex-direction: column;
            max-width: 1860px;

            @include mq($from: 1350px) {
                &.even {
                    display: none;
                }
            }
            
            @include mq($from: 900px, $until: 1350px) {
                &.odd {
                    display: none;
                }

                &.even {
                    display: flex;
                }
            }

            @include mq($until: 900px) {
                &.odd {
                    display: flex;
                    flex-direction: column;
                }
                
                &.even {
                    display: none;
                }
            }
        }
        
        .newsitem {
            width: 560px;
            margin: 10px !important;
            
            &:hover {
                width: 660px;
            }
        }
        
        @include mq($until: 900px) {
            align-items: center;
            margin-top: 0;

            .newsitem {
                max-width: 560px;
                width: 100%;
                margin-left: 0 !important;
                margin-right: 0 !important;

                &--info {
                    padding: 30px;
                }
                
                .text {
                    margin-top: 20px;
                    padding-right: 40px;
                }

                &:hover {
                    width: 100%;
                }
            }
        }
    }

    .pagination {
        padding-top: 200px;
        display: flex;
        justify-content: center;

        .pages {
            display: flex;
            justify-content: center;

            &.reversed {
                flex-direction: row-reverse;
            }

            .page {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 5px;

                &.active {
                    h3 {
                        color: var(--primary-color);
                    }
                }
            }
        }

        @include mq($until: $viewport--md) {
            padding-top: 100px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-top: 60px;

            .pages {
                .page {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}

.NieuwsberichtenPage {
    .header {
        margin-top: 20px;       
    }

    .news {
        &-info {
            margin-top: 80px;

            h2 {
                margin-top: 20px;
            }

            .intro {
                margin-top: 60px;
            }
        }

        @include mq($until: $viewport--sm) {
            &-info {
                margin-top: 30px;
                
                .intro {
                    margin-top: 30px;
                }
            }
        }
    }

    .titletextimageblocks {
        margin-top: 80px;

        .titletextimageblock {
            .image {
                &-container {
                    height: auto;

                    &:not(.contain) {
                        max-height: 500px;                   

                        * {
                            max-height: inherit;
                        }
                    }
                }
            }

            .text {
                font-size: 16px;
                line-height: 28px;

                * {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }

        .titletextimageblock + .titletextimageblock {
            margin-top: 60px;
        }

        @include mq($until: $viewport--sm) {
            margin-top: 50px;

            h3 {
                margin-bottom: 10px;
            }

            .titletextimageblock + .titletextimageblock {
                margin-top: 40px;
            }
        }
    }

    .share {
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        p {
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 0;
        }

        .options {
            display: flex;
            margin-top: 20px;

            a {
                height: 30px;

                svg {
                    height: inherit;

                    path {
                        transition: fill .4s ease;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: var(--primary-color);
                        }
                    }
                }
            }

            a + a {
                margin-left: 10px;
            }
        }

        @include mq($until: $viewport--sm) {
            margin-top: 40px;
        }
    }

    .back-btn {
        margin-top: 60px;

        .cta {
            width: fit-content;
        }

        @include mq($until: $viewport--sm) {
            margin-top: 40px;
        }
    }
}