.Footer {
    padding-top: 220px;
    min-height: 1080px;

    .top {
        display: flex;

        > * {
            width: 100%;
        }

        .info {
            display: flex;
            justify-content: center;
            padding: 190px 20px;
            background-color: $cc-grey;

            .contact {
                margin-right: 180px;

                .address {
                    p {
                        margin-bottom: 0;
                    }
                }

                &-data {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                }
            }

            h3 {
                color: var(--primary-color);
                margin-bottom: 40px;
            }

            a {
                color: $cc-black;

                &:hover, &:active, &:focus {
                    color: $cc-black;
                }
            }

            .socials {
                nav {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .newsletter {
            margin-left: 20px;
            background-color: var(--primary-color);
            display: flex;
            justify-content: center;
            align-items: center;

            &-content {
                > h3 {
                    color: $cc-white;
                }
            }

            .Form {
                .ff-form-errors, .ff-errors, .Form-errors, .freeform-form-has-errors {
                    color: $cc-grey;
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 600;
                }

                .input-fields {
                    &.email {
                        margin-bottom: 20px;

                        input {
                            background-color: $cc-white;
                            color: $cc-black;

                            &::placeholder {
                                color: $cc-darkGrey;
                            }
                        }

                        label {
                            display: none;
                        }
                    }

                    &.field-amount {
                        &--2 {
                            &:not(.accept) {
                                > *:not(:last-child) {
                                    margin-right: 0;
                                    width: 470px;                                    
                                }
                            }

                            .Form-item--action  {
                                width: 90px;
                                height: 90px;
                                
                                button {
                                    &[type=submit] {
                                        height: 100%;

                                        &.cta {
                                            padding: 0;
                                            background-color: $cc-black;
                                            width: inherit;
                                            clip-path: none;
                                            position: relative;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            &:after {
                                                content: url("data:image/svg+xml;charset=utf8,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_76_666)'%3E%3Cpath d='M53.3084 0L55.9976 2.68923L56 48.3939L48.3967 56V12.9821L8.94906 52.4315L3.57083 47.053L43.0185 7.60361H0L7.60328 0H53.3084Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_76_666'%3E%3Crect width='56' height='56' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                                                position: absolute;
                                                width: 56px;
                                                height: 56px;
                                                transform: scale(.9) rotate(45deg);
                                            }
                                        }
                                    }
                                }
                                
                                @include mq($until: $viewport--md) {
                                    width: 70px;
                                    height: 70px;

                                    button {
                                        &[type=submit] {
                                            &.cta {
                                                &:after {
                                                    transform: scale(.6) rotate(45deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .accept {
                    align-items: center;

                    input {
                        &:checked {
                            & ~ .fake-checkbox {
                                background-color: $cc-black !important;
                            }
                        }
                    }

                    .fake-checkbox {
                        border-color: $cc-white;
                        background-color: $cc-white;
                    }

                    label {
                        > div {
                            display: flex;
                            white-space: nowrap;
                            align-items: center;
                            color: $cc-white;

                            > a {
                                margin-left: 6px;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom {
        min-height: 280px;
        padding: 120px 220px;
        display: flex;
        justify-content: space-between;

        svg {
            max-width: 270px;

            path {
                fill: $cc-darkGrey;
            }
        }

        a {
            color: $cc-darkGrey;
            font-size: 18px;

            span {
                transition: color .4s ease;
            }

            &:hover {
                span {
                    color: var(--primary-color);
                }
            }
        }
    }

    @include mq($until: 1600px) {
        .top {
            .info {
                padding: 150px var(--spacing-nav);

                .contact {
                    margin-right: 100px;
                }
            }

            .newsletter {
                padding: 20px var(--spacing-nav);
            }
        }
    }

    @include mq($until: 1350px) {
        min-height: unset;

        .bottom {
            padding-left: var(--spacing-nav);
            padding-right: var(--spacing-nav);
        }
    }
    
    @include mq($until: 1260px) {
        .top {
            .info {
                flex-direction: column;
                align-items: flex-start;
                padding-top: 80px;
                padding-bottom: 80px;

                .contact, .socials {
                    > h3 {
                        margin-bottom: 10px;
                    }
                }

                .socials {
                    margin-top: 30px;                    
                }
            }
        }
    }

    @include mq($until: 1150px) {
        .top {
            flex-direction: column-reverse;

            .info {
                flex-direction: row;

                .socials {
                    margin-top: 0;
                }
            }

            .newsletter {
                margin-left: 0;
                padding-top: 60px;
                padding-bottom: 60px;

                .input-fields {
                    &:last-of-type {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .bottom {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            grid-template-areas: 'privacy debuf siteby';
            align-items: center;

            > nav {
                grid-area: privacy;
                display: flex;
                flex-direction: column;
            }

            > .debuf {
                grid-area: debuf;
            }
            
            > .siteby {
                grid-area: siteby;
                margin-left: auto;
            }
        }
    }

    @include mq($until: 900px) {
        .bottom {
            padding-top: 180px;
            grid-template-columns: 1fr;
            grid-template-areas:
            'debuf'
            'privacy'
            'siteby';
            padding-top: 60px;
            padding-bottom: 60px;

            > .siteby {
                margin-left: 0;
            }
        }
    }

    @include mq($until: $viewport--md) {
        padding-top: 100px;

        .top {
            .info {
                justify-content: flex-start;

                .contact {
                    margin-right: 60px;
                }
            }

            .newsletter {
                width: 100%;

                &-content {
                    width: 100%;
                    
                    .Form {
                        width: 100%;
    
                        .input-fields {
                            &.field-amount {
                                &--2 {
                                    &:not(.accept) {
                                        grid-template-columns: repeat(2, auto);
                                        width: 100%;
                                        
                                        > *:not(:last-child) {
                                            max-width: 470px;
                                            width: 100%;                                    
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
    @include mq($until: $viewport--sm) {
        .top {
            .info {
                flex-wrap: wrap;
                margin-bottom: -30px;
                
                > * {
                    margin-bottom: 30px;
                }
            }
        }
    }

    @include mq($until: 450px) {
        .accept {
            &.field-amount--2 {
                align-items: flex-start !important;
                
                .Form-controls {
                    > label {
                        > div {
                            flex-direction: column;
                            align-items: flex-start !important;

                            a {
                                margin-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}